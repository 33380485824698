import { FC } from 'react';

export const Dashboard: FC = () => {
    return (
        <div className="d-flex justify-content-center align-items-center h-100">
            <h1>Go to Weighbridge</h1>
        </div>
    );
};

export default Dashboard;
