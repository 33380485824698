import Button from 'components/ui/button/Button';
import FormInput from 'components/ui/form/form-input/FormInput';
import FormSelectCustomers from 'components/ui/form/form-select/FormSelectCustomers';
import ImageSkeleton from 'components/ui/image-skeleton/ImageSkeleton';
import LabelValue from 'components/ui/label-value/LabelValue';
import { dateTimeStandardFormat } from 'core/utils/date';
import { useFormik } from 'formik';
import useWeighbridge from 'hooks/useWeighbridge';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { UpdateWeight, Weight } from 'state/weighbridge/weighbridge';
import WeighbridgeHeader from '../WeighbridgeHeader';

const WeighingDetails: FC = () => {
    const { id, weightId } = useParams<{ id: string; weightId: string }>();
    const history = useHistory();
    const { weights, getDeviceWeights, updateWeight, error, isLoading } = useWeighbridge();
    const [weight, setWeight] = useState<Weight>();

    useEffect(() => {
        if (!weights?.list?.length) {
            getDeviceWeights(id);
        }
    }, [weights]);

    useEffect(() => {
        if (weights?.list?.length) {
            const w = weights?.list?.find((w) => w.id === weightId);
            if (w) {
                setWeight(w);
            }
        }
    }, [weights]);

    const formik = useFormik({
        initialValues: {
            manualWeight: weight?.manualWeight,
            customerId: weight?.customerId,
            dispatcher: weight?.dispatcher,
            driver: weight?.driver,
        },
        enableReinitialize: true,
        onSubmit: () => {},
    });

    const requestedDate = useMemo(() => {
        if (weight?.createdAt) {
            const date = new Date(weight?.createdAt);
            return <LabelValue label="Request date" value={dateTimeStandardFormat(date)} />;
        }
        return <></>;
    }, [weight?.createdAt]);

    const autoWeight = useMemo(
        () => <LabelValue label="Auto weight" value={weight?.autoWeight} />,
        [weight?.autoWeight]
    );

    const image = useMemo(() => {
        if (weight?.capturedWeightImage) {
            return <img src={`data:image/jpeg;base64,${weight?.capturedWeightImage}`} alt="" />;
        }
        return <ImageSkeleton />;
    }, [weight?.createdAt]);

    const updateWeightSubmit = useCallback(async () => {
        const values = { ...formik.values };
        if (weight && values) {
            const res = await updateWeight(weight.id, values as UpdateWeight);
            if (res && !error && !isLoading) {
                history.push(`/weighbridge/ready/${id}`);
                toast.success('Weighing changed with success!');
            } else {
                toast.error(error?.message ?? 'Error changing weight');
            }
        }
    }, [weight, formik?.values, id]);

    return (
        <Container fluid className="mt-2">
            <WeighbridgeHeader
                title={`Weighing - ${weightId}`}
                onClick={() => {
                    history.goBack();
                }}
                buttonText="Go back"
            />

            <div className="d-flex align-items-center justify-content-center flex-column">
                {image}
                <div className="mt-3 ">{requestedDate}</div>
                <div className="mb-3">{autoWeight}</div>
            </div>

            <div className="d-flex align-items-center justify-content-center flex-column">
                <div className="w-50">
                    <Row className="mt-2">
                        <FormInput label="Manual Weight" fieldProps={formik.getFieldProps('manualWeight')} />
                    </Row>
                    <Row className="mt-2">
                        <FormSelectCustomers
                            fieldProps={formik.getFieldProps('customerId')}
                            fieldMeta={formik.getFieldMeta('customerId')}
                            fieldHelper={formik.getFieldHelpers('customerId')}
                        />
                    </Row>
                    <Row className="mt-2">
                        <FormInput label="driver" fieldProps={formik.getFieldProps('driver')} />
                    </Row>
                    <Row className="mt-2">
                        <FormInput label="Dispatcher" fieldProps={formik.getFieldProps('dispatcher')} />
                    </Row>
                </div>
            </div>
            <Row className="mt-5">
                <Col className="d-flex justify-content-center">
                    <Button
                        onClick={() => {
                            updateWeightSubmit();
                        }}
                    >
                        Update Weighing
                    </Button>
                </Col>
            </Row>
        </Container>
    );
};

export default WeighingDetails;
