import Button from 'components/ui/button/Button';
import CustomModal from 'components/ui/modal/CustomModal';
import { defaultPagination, PaginatedParams } from 'core/http/pagination';
import useWeighbridge from 'hooks/useWeighbridge';
import { FC, useCallback, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { WeighbridgeStatus } from 'state/weighbridge/weighbridge';
import WeighbridgeTable from './components/WeighbridgeTable';

export const Weighbridge: FC = () => {
    const history = useHistory();
    const { devices, getDevices, deleteDevice, error, isLoading } = useWeighbridge();
    const [showModal, setShowModal] = useState(false);
    const [deleteId, setDeleteId] = useState<string>();
    const [paginator, setPaginator] = useState<Partial<PaginatedParams>>(defaultPagination);

    useEffect(() => {
        if (!paginator) {
            getDevices(defaultPagination);
        }
    }, []);

    useEffect(() => {
        if (paginator) {
            getDevices(paginator);
        }
    }, [paginator]);

    const onDelete = useCallback((id: string) => {
        setDeleteId(id);
        setShowModal(true);
    }, []);

    const handlePagination = useCallback(
        (params: Partial<PaginatedParams>) => {
            if (paginator) {
                setPaginator({ ...paginator, page: params.page, size: paginator.size });
            } else {
                setPaginator({ ...defaultPagination, page: params.page });
            }
        },
        [paginator]
    );

    const deleteDeviceById = useCallback(async () => {
        if (deleteId) {
            await deleteDevice(deleteId);
            if (!error && !isLoading) {
                setShowModal(false);
                getDevices();
                toast.success('Device was deleted with success!');
            } else {
                toast.error(error?.message ?? 'Failed to delete device.');
            }
        }
    }, [deleteId]);

    return (
        <Container fluid className="mt-2">
            <Row className="mb-3">
                <Col>
                    <h3>Weighbridges</h3>
                </Col>

                <Col className="d-flex justify-content-end">
                    <Button
                        onClick={() => {
                            history.push({
                                pathname: `/weighbridge/add-device`,
                            });
                        }}
                    >
                        Add device
                    </Button>
                </Col>
            </Row>

            <WeighbridgeTable
                data={devices}
                onDetails={(device) => {
                    if (device?.status === WeighbridgeStatus.READY) {
                        history.push(`/weighbridge/ready/${device.id}`);
                    } else {
                        history.push(`/weighbridge/pending-setup/${device.id}`);
                    }
                }}
                onDelete={onDelete}
                onPagination={handlePagination}
                loading={isLoading}
            />
            <CustomModal
                cancelText="Don't remove"
                confirmText="Yes, remove"
                title="Do you want to remove this device?"
                show={showModal}
                onClose={() => setShowModal(false)}
                onSave={() => deleteDeviceById()}
            >
                If you remove this device, you will lose all the information associated with it.
            </CustomModal>
        </Container>
    );
};

export default Weighbridge;
