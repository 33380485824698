import useSession from 'hooks/useSession';
import { useEffect, useState } from 'react';
import api from './axios';

const useAxiosInterceptors = () => {
    const { logout, updateToken, getToken } = useSession();
    const [ready, setReady] = useState(false);

    const updateTokenStore = () => {
        const authStorage = getToken();
        if (authStorage) {
            const store = JSON.parse(authStorage);
            return store.token;
        }
    };

    useEffect(() => {
        // add request interceptors
        api.interceptors.request.use(async (config) => {
            try {
                const newHeaders: { Authorization?: string } = {};
                const token: string = updateTokenStore();
                if (token) {
                    newHeaders['Authorization'] = `Bearer ${token}`;
                }

                return {
                    ...config,
                    headers: {
                        ...config.headers,
                        ...newHeaders,
                    },
                };
            } catch (error) {
                return config;
            }
        });

        // add response interceptors
        api.interceptors.response.use(
            (response) => response,
            async (error) => {
                if (error) {
                    const { response } = error;
                    if (response) {
                        const { status } = response;
                        const originalConfig = error.config;
                        if (status === 401) {
                            if (
                                originalConfig.method !== 'post' &&
                                originalConfig.url !== '/auth/session' &&
                                error.response &&
                                !originalConfig._retry
                            ) {
                                originalConfig._retry = true;
                                await updateToken();
                                return api(originalConfig);
                            } else {
                                logout();
                            }
                        }
                    }
                }
                return Promise.reject(error);
            }
        );
        setReady(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logout]);

    return ready;
};

export default useAxiosInterceptors;
