import { Navbar, NavbarBrand } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledNavbar = styled(Navbar)<{ collapsed: boolean }>`
    background: #ffff;
    margin-left: ${({ collapsed }) => (collapsed ? '64px' : '265px')};
    padding: 8px;
`;

export const StyledNavbarBrand = styled(NavbarBrand)`
    cursor: pointer;
`;
