import LabelValue from 'components/ui/label-value/LabelValue';
import MapContainer from 'components/ui/map/MapContainer';
import { FC, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Device } from 'state/weighbridge/weighbridge';
import styled from 'styled-components';
import { sortCoordinates } from 'views/weightbridge/utils/weightbridge-utils';

const MapRow = styled(Row)`
    height: 50vh;
`;

const CoordinatesCol = styled(Col)`
    height: 50vh;
    overflow: auto;
`;

interface Props {
    device?: Device;
}
const GeofenceCoordinates: FC<Props> = ({ device }) => {
    const geofenceCoordinates = useMemo(() => {
        if (device?.geofenceCoordinates) {
            const sorted = sortCoordinates(device?.geofenceCoordinates);
            return sorted.map((c, index: number) => (
                <div className="mb-3" key={`${c.latitude}-${c.longitude}-${index}`}>
                    <strong>Point {index + 1}:</strong>
                    <div className="ms-4">
                        <LabelValue label="Latitude" value={`${c.latitude}`} />
                        <LabelValue label="Longitude" value={`${c.longitude}`} />
                    </div>
                </div>
            ));
        }
        return <></>;
    }, [device]);

    return (
        <MapRow className="mt-3" style={{ height: '50vh' }}>
            <Col md={8}>
                <MapContainer coordinates={device?.geofenceCoordinates} />
            </Col>
            <CoordinatesCol md={3}>
                <h4 className="mb-4">Device coordinates</h4>
                {geofenceCoordinates}
            </CoordinatesCol>
        </MapRow>
    );
};

export default GeofenceCoordinates;
