import EmptyTable from 'components/icons/EmptyTable';
import { PaginatedParams } from 'core/http/pagination';
import { ListPayload } from 'core/http/response';
import { FC, ReactNode, useMemo } from 'react';
import { Table as BTable } from 'react-bootstrap';
import styled from 'styled-components';
import Loader from '../loader/Loader';
import Pagination from '../pagination/Pagination';

export interface TableColumns {
    title: string;
    render: (rowData: any) => ReactNode;
}

interface Props {
    onRow?: (index: number) => void;
    columns: TableColumns[];
    data?: ListPayload<any>;
    bordered?: boolean;
    hover?: boolean;
    striped?: boolean;
    isDark?: boolean;
    loading?: boolean;
    onPagination?: (paginator: Partial<PaginatedParams>) => void;
}

const StyledTr = styled.tr<{ pointer: boolean }>`
    cursor:${({ pointer }) => (pointer ? 'pointer' : 'auto')}};
`;

const Table: FC<Props> = ({ columns, data, onRow, loading, onPagination, ...tableProps }) => {
    const hasPaginationInfo = useMemo(
        () => Number.isInteger(data?.pageNumber) && Number.isInteger(data?.total) && Number.isInteger(data?.pageSize),
        [data]
    );

    const paginator = useMemo(() => {
        return {
            currentPage: data?.pageNumber ?? 0,
            totalElements: data?.total ?? 0,
            elementsPerPage: data?.pageSize ?? 10,
        };
    }, [data]);

    const handleNullValues = (value: string | number | React.ReactNode) => {
        return value ? <span>{value}</span> : <span>N/A</span>;
    };

    return (
        <>
            <BTable responsive {...tableProps}>
                <thead>
                    <tr>
                        {columns.map((headerItem, index) => (
                            <th key={index}>{headerItem.title}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {!loading &&
                        !!data?.list?.length &&
                        data?.list?.map((item, index) => (
                            <StyledTr
                                pointer={!!onRow}
                                key={index}
                                onClick={(e) => {
                                    e.preventDefault();
                                    onRow && onRow(index);
                                }}
                            >
                                {columns.map((col, key) => (
                                    <td key={key}>{handleNullValues(col.render(item))}</td>
                                ))}
                            </StyledTr>
                        ))}
                </tbody>
            </BTable>

            <Loader isLoading={loading}>
                {!!!data?.list.length && (
                    <div className="d-flex justify-content-center mt-2">
                        <div className="flex-column">
                            <EmptyTable />
                            <div className="d-flex justify-content-center">
                                <p>No data</p>
                            </div>
                        </div>
                    </div>
                )}
            </Loader>

            {!loading && hasPaginationInfo && !!data?.list.length && (
                <Pagination
                    paginator={paginator}
                    onChanged={(e) => {
                        onPagination && onPagination({ page: e.currentPage, size: e.totalElements });
                    }}
                />
            )}
        </>
    );
};
export default Table;
