import Dashboard from 'views/dashboard/Dashboard';
import AddDevice from 'views/weightbridge/components/AddDevice';
import Calibrate from 'views/weightbridge/components/calibrations/Calibrate';
import CalibrationDetails from 'views/weightbridge/components/calibrations/CalibrationDetails';
import Geofence from 'views/weightbridge/components/calibrations/Geofence';
import ListCalibrations from 'views/weightbridge/components/calibrations/ListCalibrations';
import WeighbridgePendingSetup from 'views/weightbridge/components/details/WeighbridgePendingSetup';
import WeighbridgeReady from 'views/weightbridge/components/details/WeighbridgeReady';
import WeighingDetails from 'views/weightbridge/components/weighing/WeighingDetails';
import Weighbridge from 'views/weightbridge/Weightbridge';

interface Route {
    path: string;
    name: string;
    exact: boolean;
    component: any;
    props?: any;
}

const routes: Route[] = [
    {
        path: '/home',
        name: 'HomePage',
        component: Dashboard,
        exact: true,
    },
    {
        path: '/weighbridge',
        name: 'Weighbridge',
        component: Weighbridge,
        exact: true,
    },
    {
        path: '/weighbridge/ready/:id',
        name: 'Weighbridge ready',
        component: WeighbridgeReady,
        exact: true,
    },
    {
        path: '/weighbridge/pending-setup/:id',
        name: 'Weighbridge pending setup',
        component: WeighbridgePendingSetup,
        exact: true,
    },
    {
        path: '/weighbridge/pending-setup/:id/geofence',
        name: 'Geofence',
        component: Geofence,
        exact: true,
    },
    {
        path: '/weighbridge/pending-setup/:id/calibrate/:calibrationId',
        name: 'Calibrate',
        component: Calibrate,
        exact: true,
    },
    {
        path: '/weighbridge/add-device',
        name: 'Add Device',
        component: AddDevice,
        exact: true,
    },
    {
        path: '/weighbridge/ready/:id/weighing/:weightId',
        name: 'WeighingDetails',
        component: WeighingDetails,
        exact: true,
    },
    {
        path: '/weighbridge/ready/:id/calibrations',
        name: 'Calibrations',
        component: ListCalibrations,
        exact: true,
    },
    {
        path: '/weighbridge/ready/:id/calibrations/:calibrationId',
        name: 'Calibrations details',
        component: CalibrationDetails,
        exact: true,
    },
];

export default routes;
