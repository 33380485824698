import { FC } from 'react';
import styled from 'styled-components';
import { colors } from 'theme/colors';

const Skeleton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 166px;
    width: 565px;
    background: ${colors.grey};
`;

export const ImageSkeleton: FC = () => {
    return (
        <Skeleton>
            <strong>No image available</strong>
        </Skeleton>
    );
};

export default ImageSkeleton;
