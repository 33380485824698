import { ErrorResponse } from 'core/http/response';
import { isString } from 'lodash';

// TODO: improve this function
export const handleError = (dispatch: Function, type: string, e: any) => {
    if (isString(e)) {
        const error = { message: e } as ErrorResponse;
        dispatch({
            type: type,
            error: error,
        });
    } else {
        dispatch({
            type: type,
            error: e?.response?.data,
        });
    }
};
