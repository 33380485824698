import { handleError } from 'state/state-utils';
import { signIn, updateToken } from './client';
import { LoginCredentials } from './user';

const hasRememberUser = () => (localStorage.getItem('auth') ? true : false);

export const doSignIn: Function = (credentials: LoginCredentials) => async (dispatch: Function) => {
    dispatch({ type: 'USER_SIGN_IN_REQUEST' });
    try {
        const payload = await signIn(credentials);
        dispatch({ type: 'USER_SIGN_IN_SUCCESS', payload });

        if (credentials.remember) {
            localStorage.setItem('auth', JSON.stringify(payload));
        } else {
            sessionStorage.setItem('auth', JSON.stringify(payload));
        }
        return payload;
    } catch (e) {
        handleError(dispatch, 'USER_SIGN_IN_ERROR', e);
    }
};

export const doUpdateToken: Function = (token: string) => async (dispatch: Function) => {
    dispatch({ type: 'REFRESH_TOKEN_REQUEST' });
    try {
        const response = await updateToken(token);
        dispatch({ type: 'REFRESH_TOKEN_SUCCESS', payload: response });
        if (hasRememberUser()) {
            localStorage.setItem('auth', JSON.stringify(response));
        } else {
            sessionStorage.setItem('auth', JSON.stringify(response));
        }
        return response;
    } catch (e: any) {
        handleError(dispatch, 'REFRESH_TOKEN_ERROR', e);
    }
};
