import Button from 'components/ui/button/Button';
import Loader from 'components/ui/loader/Loader';
import { Polygon } from 'components/ui/map/map';
import Map from 'components/ui/map/MapContainer';
import useWeighbridge from 'hooks/useWeighbridge';
import { FC, useCallback, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Coordinate, Device } from 'state/weighbridge/weighbridge';
import WeighbridgeHeader from '../WeighbridgeHeader';

export const Geofence: FC = () => {
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    const [polygon, setPolygon] = useState<Polygon>();
    const { device, getDevice, updateDevice, error, isLoading } = useWeighbridge();

    useEffect(() => {
        if (device?.id !== id) {
            getDevice(id);
        }
    }, []);

    const geofenceCoordinates = useCallback(() => {
        if (polygon?.type === 'Point') {
            const points = polygon.coordinates as number[];
            const coordinates: Coordinate = { latitude: points[0], longitude: points[1], orderIndex: 0 };
            return [coordinates];
        } else if (polygon?.type === 'Polygon') {
            const p = polygon?.coordinates as number[][][];
            return p[0]?.map((c, i) => {
                const points = c as number[];
                return { latitude: points[0], longitude: points[1], orderIndex: i };
            });
        } else {
            return [];
        }
    }, [polygon]);

    const updateGeofance = useCallback(async () => {
        const coordinates = geofenceCoordinates();
        const updatedDevice: Partial<Device> = { ...device, geofenceCoordinates: coordinates };
        const updated = await updateDevice(updatedDevice);
        if (updated && !error && !isLoading) {
            history.goBack();
            toast.success('Geofence updated with success!');
        } else {
            toast.error(error?.message ?? 'Failed to update geofence.');
        }
    }, [geofenceCoordinates, history, error, isLoading]);

    return (
        <Loader isLoading={isLoading}>
            <Container fluid className="mt-2">
                <WeighbridgeHeader title="Geofence device" buttonText="Go back" onClick={() => history.goBack()} />

                <Row>
                    <Col className="d-flex justify-content-center">
                        <Map drawEnabled onDraw={(p) => setPolygon(p)} onDelete={() => setPolygon(undefined)} />
                    </Col>
                </Row>

                <Button
                    className="mt-5"
                    disabled={!polygon}
                    onClick={() => {
                        if (polygon) {
                            updateGeofance();
                        }
                    }}
                >
                    Save
                </Button>
            </Container>
        </Loader>
    );
};

export default Geofence;
