import Table, { TableColumns } from 'components/ui/table/Table';
import { PaginatedParams } from 'core/http/pagination';
import { ListPayload } from 'core/http/response';
import { dateTimeStandardFormat } from 'core/utils/date';
import { enumToText } from 'core/utils/enum-to-text';
import { FC } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Weight } from 'state/weighbridge/weighbridge';

interface Props {
    data?: ListPayload<Weight>;
    loading?: boolean;
    onDetails: (id: string) => void;
    onPagination?: (paginator: Partial<PaginatedParams>) => void;
}

const WeighingTable: FC<Props> = ({ data, onDetails, loading, onPagination }) => {
    const columns: TableColumns[] = [
        {
            title: 'Id',
            render: (record: Weight) => (
                <OverlayTrigger overlay={<Tooltip>{record.id}</Tooltip>}>
                    <span>{`${record.id.substring(0, 7)}...`}</span>
                </OverlayTrigger>
            ),
        },
        {
            title: 'Type',
            render: (record: Weight) => enumToText(record.requestType),
        },
        {
            title: 'Auto Weight',
            render: (record: Weight) => record.autoWeight,
        },
        {
            title: 'Manual Weight',
            render: (record: Weight) => record.manualWeight,
        },
        {
            title: 'Driver',
            render: (record: Weight) => record.driver,
        },
        {
            title: 'Dispatcher',
            render: (record: Weight) => record.dispatcher,
        },
        {
            title: 'Date',
            render: (record: Weight) => {
                if (record?.createdAt) {
                    const date = new Date(record.createdAt);
                    return dateTimeStandardFormat(date);
                }
                return <span>N/A</span>;
            },
        },
    ];

    return (
        <>
            <Table
                loading={loading}
                columns={columns}
                hover
                data={data}
                onRow={(i) => {
                    if (onDetails && data?.list) {
                        onDetails(data.list[i].id);
                    }
                }}
                onPagination={onPagination}
            />
        </>
    );
};
export default WeighingTable;
