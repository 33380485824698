import LabelValue from 'components/ui/label-value/LabelValue';
import Table, { TableColumns } from 'components/ui/table/Table';
import { PaginatedParams } from 'core/http/pagination';
import { ListPayload } from 'core/http/response';
import { dateTimeStandardFormat } from 'core/utils/date';
import { FC } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Calibration } from 'state/weighbridge/weighbridge';

interface Props {
    data?: ListPayload<Calibration>;
    loading?: boolean;
    onDetails: (id: string) => void;
    onPagination?: (paginator: Partial<PaginatedParams>) => void;
}

const CalibrationsTable: FC<Props> = ({ data, onDetails, loading, onPagination }) => {
    const columns: TableColumns[] = [
        {
            title: 'Id',
            render: (record: Calibration) => (
                <OverlayTrigger overlay={<Tooltip>{record.id}</Tooltip>}>
                    <span>{`${record.id.substring(0, 7)}...`}</span>
                </OverlayTrigger>
            ),
        },
        {
            title: 'Trace Id',
            render: (record: Calibration) => (
                <OverlayTrigger overlay={<Tooltip>{record.traceId}</Tooltip>}>
                    <span>{`${record.traceId.substring(0, 7)}...`}</span>
                </OverlayTrigger>
            ),
        },
        {
            title: 'Customer Id',
            render: (record: Calibration) => (
                <OverlayTrigger overlay={<Tooltip>{record.createdBy}</Tooltip>}>
                    <span>{`${record.createdBy.substring(0, 7)}...`}</span>
                </OverlayTrigger>
            ),
        },
        {
            title: 'Top Left Point',
            render: (record: Calibration) => (
                <>
                    <LabelValue label="X" value={record?.topLeftX}></LabelValue>
                    <LabelValue label="Y" value={record?.topLeftY}></LabelValue>
                </>
            ),
        },
        {
            title: 'Bottom Right Point',
            render: (record: Calibration) => (
                <>
                    <LabelValue label="X" value={record?.bottomRightX}></LabelValue>
                    <LabelValue label="Y" value={record?.bottomRightY}></LabelValue>
                </>
            ),
        },

        {
            title: 'Date',
            render: (record: Calibration) => {
                if (record?.createdAt) {
                    const date = new Date(record.createdAt);
                    return dateTimeStandardFormat(date);
                }
                return <span>N/A</span>;
            },
        },
    ];

    return (
        <Table
            hover
            data={data}
            columns={columns}
            onRow={(i) => {
                if (onDetails && data?.list) {
                    onDetails(data.list[i].id);
                }
            }}
            loading={loading}
            onPagination={onPagination}
        />
    );
};
export default CalibrationsTable;
