import { PaginatedParams } from 'core/http/pagination';
import { ListPayload, ListResponse, SuccessResponse } from 'core/http/response';
import axiosClient from '../../config/axios';
import { CalibratePayload, Calibration, CreateDevicePayload, Device, UpdateWeight, Weight } from './weighbridge';

export const getDevices = (params?: Partial<PaginatedParams>): Promise<ListPayload<Device>> => {
    return axiosClient
        .get<ListResponse<Device>>(`/weighbridge-service/devices`, { params })
        .then((response) => response.data.payload);
};

export const getDevice = (id: string): Promise<Device> => {
    return axiosClient
        .get<SuccessResponse<Device>>(`/weighbridge-service/devices/${id}`)
        .then((response) => response.data.payload);
};

export const addDevice = (device: CreateDevicePayload): Promise<Device> => {
    return axiosClient
        .post<SuccessResponse<Device>>(`/weighbridge-service/devices`, { ...device })
        .then((response) => response.data.payload);
};

export const updateDevice = (device: Partial<Device>): Promise<Device> => {
    return axiosClient
        .put<SuccessResponse<Device>>(`/weighbridge-service/devices/${device.id}`, { ...device })
        .then((response) => response.data.payload);
};

export const deleteDevice = (deviceId: string): Promise<void> => {
    return axiosClient
        .delete<SuccessResponse<void>>(`/weighbridge-service/devices/${deviceId}`)
        .then((response) => response.data.payload);
};

export const calibrateDevice = (
    deviceId: string,
    calibrationId: string,
    calibrate: CalibratePayload
): Promise<Device> => {
    return axiosClient
        .post<SuccessResponse<Device>>(`/weighbridge-service/weighbridges/${deviceId}/calibrations/${calibrationId}`, {
            ...calibrate,
        })
        .then((response) => response.data.payload);
};

export const startCalibration = (deviceId: string): Promise<Calibration> => {
    return axiosClient
        .post<SuccessResponse<Calibration>>(`/weighbridge-service/weighbridges/${deviceId}/recalibrate`, {})
        .then((response) => response.data.payload);
};

export const getDeviceWeights = (id: string, params?: Partial<PaginatedParams>): Promise<ListPayload<Weight>> => {
    return axiosClient
        .get<ListResponse<Weight>>(`/weighbridge-service/weighbridges/${id}/weights`, { params })
        .then((response) => response.data.payload);
};

export const getDeviceCalibrations = (
    id: string,
    params?: Partial<PaginatedParams>
): Promise<ListPayload<Calibration>> => {
    return axiosClient
        .get<ListResponse<Calibration>>(`/weighbridge-service/devices/${id}/calibrations`, { params })
        .then((response) => response.data.payload);
};

export const getWeight = (id: string): Promise<Weight> => {
    return axiosClient
        .post<SuccessResponse<Weight>>(`/weighbridge-service/weighbridges/${id}/weigh`)
        .then((response) => response.data.payload);
};

export const updateWeight = (id: string, toUpdate: UpdateWeight): Promise<Weight> => {
    return axiosClient
        .put<SuccessResponse<Weight>>(`/weighbridge-service/weighbridges/weights/${id}`, { ...toUpdate })
        .then((response) => response.data.payload);
};
