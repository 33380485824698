import ImageSkeleton from 'components/ui/image-skeleton/ImageSkeleton';
import Loader from 'components/ui/loader/Loader';
import useWeighbridge from 'hooks/useWeighbridge';
import { FC, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { Calibration } from 'state/weighbridge/weighbridge';
import CalibrateImage from '../image/CalibrateImage';
import WeighbridgeHeader from '../WeighbridgeHeader';

export const CalibrationDetails: FC = () => {
    const { id, calibrationId } = useParams<{ id: string; calibrationId: string }>();
    const history = useHistory();
    const [lastCalibration, setLastCalibration] = useState<Calibration>();

    const { device, getDevice, isLoading } = useWeighbridge();

    useEffect(() => {
        getDevice(id);
    }, []);

    useEffect(() => {
        if (!!device?.calibrations?.length) {
            const c = device?.calibrations.find((c) => c.id === calibrationId);
            if (c) {
                setLastCalibration(c);
            }
        }
    }, [device]);

    return (
        <Loader isLoading={isLoading}>
            <Container fluid className="mt-2">
                <WeighbridgeHeader title="Calibration" buttonText="Go back" onClick={() => history.goBack()} />

                {lastCalibration ? (
                    <CalibrateImage
                        image={lastCalibration.base64Image}
                        drawEnabled={false}
                        calibration={lastCalibration}
                    />
                ) : (
                    <div className="d-flex justify-content-center">
                        <ImageSkeleton />
                    </div>
                )}
            </Container>
        </Loader>
    );
};

export default CalibrationDetails;
