import { FC, useState } from 'react';
import { Alert as BAlert } from 'react-bootstrap';
import { Variant } from 'react-bootstrap/esm/types';
import styled from 'styled-components';

const StyledAlert = styled(BAlert)`
    padding: 8px;
`;

interface Props {
    type: Variant;
    closable?: boolean;
}
export const Alert: FC<Props> = ({ type, closable, children }) => {
    const [show, setShow] = useState(true);

    return (
        <>
            {show && (
                <StyledAlert variant={type} onClose={() => setShow(false)} dismissible={closable}>
                    {children}
                </StyledAlert>
            )}
        </>
    );
};

export default Alert;
