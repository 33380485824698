import 'bootstrap/dist/css/bootstrap.min.css';
import useAxiosInterceptors from 'config/useAxiosInterceptors';
import useSession from 'hooks/useSession';
import { FC, useEffect } from 'react';
import AppRouter from './config/routes/AppRouter';

const App: FC = () => {
    const { logout } = useSession();
    const ready = useAxiosInterceptors();
    useEffect(() => {
        window.addEventListener('storage', (event) => {
            const credentials = sessionStorage.getItem('auth');

            if (event.key === 'REQUESTING_SHARED_CREDENTIALS' && credentials) {
                localStorage.setItem('CREDENTIALS_SHARING', credentials);
                localStorage.removeItem('CREDENTIALS_SHARING');
            }

            if (event.key === 'CREDENTIALS_SHARING' && credentials === null) {
                if (event.newValue) {
                    sessionStorage.setItem('auth', event.newValue);
                }
            }

            if (event.key === 'CREDENTIALS_FLUSH' && credentials) {
                sessionStorage.removeItem('auth');
                logout();
            }
        });
    }, [logout]);

    return <>{ready && <AppRouter />}</>;
};

export default App;
