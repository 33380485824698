import { createBrowserHistory } from 'history';
import useSession from 'hooks/useSession';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Login from 'views/login/Login';
import Skeleton from '../../views/skeleton/Skeleton';
const history = createBrowserHistory();

const AppRouter = () => {
    const { isLogged } = useSession();

    return (
        <>
            <ToastContainer />
            <Router history={history}>
                <Switch>
                    <Route exact path="/">
                        {isLogged ? <Redirect to="/weighbridge" /> : <Login />}
                    </Route>
                    <Skeleton />
                </Switch>
            </Router>
        </>
    );
};
export default AppRouter;
