export const defaultLatitude = -6.272250963000388;
export const defaultLongitude = 53.348783340728005;

export interface Polygon {
    type: 'Point' | 'Polygon';
    coordinates: number[] | number[][][];
}

export enum MapActions {
    DELETE = 'draw.delete',
    CREATE = 'draw.create',
    UPDATE = 'draw.update',
}
