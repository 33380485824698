import Button from 'components/ui/button/Button';
import FormInput from 'components/ui/form/form-input/FormInput';
import { objectIsEmpty } from 'core/utils/objects';
import { setNestedObjectValues, useFormik } from 'formik';
import useSession from 'hooks/useSession';
import { FC, useCallback, useEffect } from 'react';
import { Form, Row } from 'react-bootstrap';
import { LoginCredentials } from 'state/session/user';
import styled from 'styled-components';
import * as Yup from 'yup';
import logo from './../../assets/login-logo.svg';

const Card = styled.div`
    width: 500px;
    margin: auto;
    padding: 40px;
    border-radius: 15px;
    text-align: left;
`;

export const TitleH1 = styled.h1`
    font-size: 3.8rem;
    line-height: 4.6rem;
    font-weight: regular;
    margin-left: 2.8rem;
    position: fixed;
    left: 0;
    top: 0;
`;

const validationSchema = Yup.object().shape({
    email: Yup.string().email('Email is invalid.').required('Email is missing.'),
    password: Yup.string().required('Password is missing.'),
});

const Login: FC = () => {
    const { signIn, isLoading } = useSession();

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validateOnMount: true,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: () => {},
    });

    const handleSubmit = async () => {
        const values = { ...formik.values };
        const c: LoginCredentials = { remember: false, email: values.email, password: values.password };
        await signIn(c);
    };

    const validate = async () => {
        const v = await formik.validateForm().then((v) => {
            formik.setTouched(setNestedObjectValues(v, true));
            return v;
        });
        if (!objectIsEmpty(v)) {
            return false;
        } else {
            return true;
        }
    };

    const login = useCallback(async () => {
        const valid = await validate();
        if (valid) {
            await handleSubmit();
        }
    }, [handleSubmit]);

    useEffect(() => {
        const checkIfClickedEnterKey = (event: KeyboardEvent) => {
            if (event.code === 'Enter') {
                login();
            }
        };
        document.addEventListener('keydown', checkIfClickedEnterKey);
        return () => {
            document.removeEventListener('keydown', checkIfClickedEnterKey);
        };
    }, [login]);

    return (
        <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center">
            <TitleH1>
                <img src={logo} alt="DDispatch" />
            </TitleH1>
            <Card className="shadow-lg">
                <div className="d-flex justify-content-center mb-5">
                    <h4>Sign In</h4>
                </div>
                <Form className="h-100">
                    <Row className="mb-3">
                        <FormInput
                            autoFocus
                            label="Email"
                            fieldProps={formik.getFieldProps('email')}
                            fieldMeta={formik.getFieldMeta('email')}
                        />
                    </Row>
                    <Row className="mb-3">
                        <FormInput
                            label="Password"
                            type="password"
                            fieldProps={formik.getFieldProps('password')}
                            fieldMeta={formik.getFieldMeta('password')}
                        />
                    </Row>

                    <div className="d-grid mt-5">
                        <Button onClick={() => login()} loading={isLoading}>
                            Sign In
                        </Button>
                    </div>
                </Form>
            </Card>
        </div>
    );
};

export default Login;
