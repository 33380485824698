import loggerMiddleware from 'core/logger';
import { applyMiddleware, combineReducers, createStore, Reducer } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import customers, { customersInitialState, CustomersState } from './customers/reducer';
import session, { sessionInitialState, SessionState } from './session/reducers';
import weighbridge, { weighbridgesInitialState, WeighbridgeState } from './weighbridge/reducer';

interface ApplicationState {
    session: SessionState;
    weighbridge: WeighbridgeState;
    customers: CustomersState;
}

const reducers: Reducer<ApplicationState> = combineReducers<ApplicationState>({
    session,
    weighbridge,
    customers,
});

const configureStore = () => {
    const middlewareEnhancer = applyMiddleware(...[loggerMiddleware, thunkMiddleware]);
    const composeEnhancers = composeWithDevTools({});
    // const enhancers = [middlewareEnhancer];

    // // If Redux DevTools Extension is installed use it, otherwise use Redux compose
    // const composeEnhancers =
    //   (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
    //   (window as any).__REDUX_DEVTOOLS_EXTENSION__();

    const composedEnhancers = composeEnhancers(middlewareEnhancer);
    const initialState: ApplicationState = {
        session: sessionInitialState,
        weighbridge: weighbridgesInitialState,
        customers: customersInitialState,
    };
    return createStore(reducers, initialState, composedEnhancers);
};

export const store = configureStore();
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
