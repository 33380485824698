import { FieldHelperProps, FieldInputProps, FieldMetaProps } from 'formik';
import { FC, useMemo } from 'react';
import { Form } from 'react-bootstrap';

export interface SelectOption {
    id: string;
    value: string;
}

interface Props {
    label: string;
    placeholder?: string;
    fieldProps: FieldInputProps<any>;
    fieldMeta?: FieldMetaProps<any>;
    fieldHelper?: FieldHelperProps<any>;
    autoFocus?: boolean;
    selectOptions: SelectOption[];
}
const FormSelect: FC<Props> = ({ label, fieldProps, fieldMeta, selectOptions, placeholder }) => {
    const hasErrorAndTouched = useMemo(() => !!fieldMeta?.error && !!fieldMeta.touched, [fieldMeta]);

    return (
        <Form.Group>
            <Form.Label>{label}</Form.Label>
            <Form.Control isInvalid={hasErrorAndTouched} as="select" {...fieldProps}>
                <option value="" selected hidden>
                    {placeholder}
                </option>
                {selectOptions.map((item, key) => (
                    <option key={key} value={item.id}>
                        {item.value}
                    </option>
                ))}
            </Form.Control>
            <Form.Text className="invalid-feedback">{fieldMeta?.error}</Form.Text>
        </Form.Group>
    );
};
export default FormSelect;
