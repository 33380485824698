import { FieldHelperProps, FieldInputProps, FieldMetaProps } from 'formik';
import useCustomers from 'hooks/useCustomers';
import { FC, useEffect } from 'react';
import { normalizeCustomersToSelect } from 'state/customers/customer';
import FormSelect from './FormSelect';

interface Props {
    fieldProps: FieldInputProps<any>;
    fieldMeta?: FieldMetaProps<any>;
    fieldHelper?: FieldHelperProps<any>;
}
const FormSelectCustomers: FC<Props> = (props) => {
    const { listCustomers, customers } = useCustomers();

    useEffect(() => {
        if (!customers?.list?.length) {
            listCustomers({ size: 200 }); //TODO
        }
    }, []);

    return (
        <FormSelect
            placeholder="Select customers..."
            label="Customer"
            selectOptions={normalizeCustomersToSelect(customers?.list)}
            {...props}
        />
    );
};

export default FormSelectCustomers;
