import { FieldInputProps, FieldMetaProps } from 'formik';
import { FC, useMemo } from 'react';
import { Form } from 'react-bootstrap';

interface Props {
    type?: 'text' | 'password';
    label: string;
    placeholder?: string;
    fieldProps?: FieldInputProps<any>;
    fieldMeta?: FieldMetaProps<any>;
    autoFocus?: boolean;
}
const FormInput: FC<Props> = ({ label, placeholder, fieldProps, fieldMeta, type = 'text', autoFocus }) => {
    const hasErrorAndTouched = useMemo(() => !!fieldMeta?.error && !!fieldMeta.touched, [fieldMeta]);

    return (
        <Form.Group>
            <Form.Label>{label}</Form.Label>
            <Form.Control
                autoFocus={autoFocus}
                type={type}
                isInvalid={hasErrorAndTouched}
                placeholder={placeholder}
                {...fieldProps}
            />
            <Form.Text className="invalid-feedback">{fieldMeta?.error}</Form.Text>
        </Form.Group>
    );
};
export default FormInput;
