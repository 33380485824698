import { ErrorResponse, ListPayload } from 'core/http/response';
import { Reducer } from 'redux';
import { CustomerActions } from './actionType';
import { Customer } from './customer';

export interface CustomersState {
    isLoading: boolean;
    error?: ErrorResponse;
    customers: ListPayload<Customer>;
}

export const customersInitialState: Readonly<CustomersState> = {
    isLoading: false,
    customers: {
        list: [],
        pageNumber: 0,
        pageSize: 0,
        total: 0,
    },
};

const customers: Reducer<CustomersState, CustomerActions> = (state = customersInitialState, action): CustomersState => {
    switch (action.type) {
        case 'LIST_CUSTOMERS_REQUEST':
            return {
                ...state,
                isLoading: true,
            };
        case 'LIST_CUSTOMERS_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                customers: action.payload,
            };
        case 'LIST_CUSTOMERS_ERROR':
            return {
                ...state,
                isLoading: false,
                customers: state.customers,
                error: action.error,
            };

        default:
            return state;
    }
};

export default customers;
