import { Button as BButton } from 'react-bootstrap';

interface Props {
    disabled?: boolean;
    loading?: boolean;
    onClick?: () => void;
    className?: string;
    size?: 'sm' | 'lg';
}
export const Button: React.FC<Props> = ({ loading, onClick, children, ...remainingProps }) => {
    return (
        <BButton
            {...remainingProps}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onClick && onClick();
            }}
        >
            {loading ? (
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
            ) : (
                <>{children}</>
            )}
        </BButton>
    );
};

export default Button;
