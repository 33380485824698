import CustomAlert from 'components/ui/alert/Alert';
import Button from 'components/ui/button/Button';
import Loader from 'components/ui/loader/Loader';
import useWeighbridge from 'hooks/useWeighbridge';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { WeighbridgeStatus } from 'state/weighbridge/weighbridge';
import WeighbridgeHeader from '../WeighbridgeHeader';
import DeviceInfo from './DeviceInfo';
import GeofenceCoordinates from './GeofenceCoordinates';

export const WeighbridgePendingSetup: FC = () => {
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    const { device, getDevice, startCalibrate, error, isLoading } = useWeighbridge();

    useEffect(() => {
        getDevice(id);
    }, []);

    const hasGeofence = useMemo(() => !!device?.geofenceCoordinates?.length, [device]);

    const hasCalibration = useMemo(() => !!device?.calibrations?.length, [device]);

    const handleStartCalibration = useCallback(async () => {
        if (device) {
            const start = await startCalibrate(device?.id);
            if (!!start && !error && !isLoading) {
                history.push(`/weighbridge/pending-setup/${device?.id}/calibrate/${start.id}`);
                toast.success('Request calibration with success!');
            } else {
                toast.error(error?.message ?? 'Failed to request calibration.');
            }
        }
    }, [startCalibrate]);

    return (
        <Loader isLoading={isLoading}>
            <Container fluid className="mt-2">
                <WeighbridgeHeader
                    title={`Finish setup of device - ${device?.name}`}
                    buttonText="Go back"
                    onClick={() => {
                        history.push({
                            pathname: `/weighbridge`,
                        });
                    }}
                />
                {device && <DeviceInfo title={<h4 className="mb-3">Device information</h4>} device={device} />}
                <h4 className="mb-3 mt-3">Configure geofence</h4>
                <Row className="mb-5">
                    <Col>
                        <Button
                            className="me-2"
                            onClick={() => history.push(`/weighbridge/pending-setup/${device?.id}/geofence`)}
                        >
                            {!hasGeofence ? 'Geofence' : 'Edit Geofence'}
                        </Button>
                    </Col>
                    {hasGeofence && <GeofenceCoordinates device={device} />}
                </Row>

                <h4 className="mb-3 ">Calibrate your device</h4>
                {device?.connected ? (
                    <>
                        {device?.status === WeighbridgeStatus.PENDING_SETUP && (
                            <Row className="mb-5">
                                <Col>
                                    <Button className="me-2" onClick={() => handleStartCalibration()}>
                                        {!hasCalibration ? 'Calibrate' : 'Edit calibration'}
                                    </Button>
                                </Col>
                            </Row>
                        )}
                    </>
                ) : (
                    <>
                        <CustomAlert type="danger">Connect your device to be able to calibrate</CustomAlert>
                    </>
                )}

                <Col className="d-flex">
                    <Button className="me-2" onClick={() => getDevice(id)}>
                        Update
                    </Button>
                </Col>
            </Container>
        </Loader>
    );
};

export default WeighbridgePendingSetup;
