import Alert from 'components/ui/alert/Alert';
import Button from 'components/ui/button/Button';
import ImageSkeleton from 'components/ui/image-skeleton/ImageSkeleton';
import { defaultPagination, PaginatedParams } from 'core/http/pagination';
import useWeighbridge from 'hooks/useWeighbridge';
import { sortBy } from 'lodash';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CalibratePayload, Calibration } from 'state/weighbridge/weighbridge';
import CalibrateImage from '../image/CalibrateImage';
import { emptyCoordinate, ImageCoordinates } from '../image/image';
import WeighbridgeHeader from '../WeighbridgeHeader';
import CalibrationsTable from './CalibrationsTable';

const ListCalibrations: FC = () => {
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const {
        getDevice,
        getDeviceCalibrations,
        calibrations,
        device,
        startCalibrate,
        calibrateDevice,
        error,
        isLoading,
    } = useWeighbridge();
    const [lastCalibration, setLastCalibration] = useState<Calibration>();
    const [initialCoordinates, setInitialCoordinates] = useState<ImageCoordinates>(emptyCoordinate);
    const [finalCoordinates, setFinalCoordinates] = useState<ImageCoordinates>(emptyCoordinate);
    const [recalibrate, setRecalibrate] = useState(false);
    const [newCalibration, setNewCalibration] = useState<Calibration>();
    const [paginator, setPaginator] = useState<Partial<PaginatedParams>>(defaultPagination);

    useEffect(() => {
        getDevice(id);

        if (!paginator) {
            getDeviceCalibrations(id, defaultPagination);
        }
    }, []);

    useEffect(() => {
        if (paginator) {
            getDeviceCalibrations(id, paginator);
        }
    }, [paginator]);

    useEffect(() => {
        if (recalibrate && !!device?.calibrations?.length && newCalibration) {
            const c = [...device?.calibrations];
            const lastCalibration = c.find((c) => c.id === newCalibration.id);
            setLastCalibration(lastCalibration);
        }
    }, [calibrations, recalibrate, newCalibration]);

    const getCalibration = useCallback(async () => {
        await getDevice(id);
        const res = await startCalibrate(id);
        if (res && !error && !isLoading) {
            setNewCalibration(res);
            setRecalibrate(true);
            await getDeviceCalibrations(id);
            toast.success('Recalibration request with success!');
        } else {
            toast.error(error?.message ?? 'Failed to request recalibration.');
        }
    }, [startCalibrate, getDeviceCalibrations, id]);

    const getCalibrationPayload = useCallback(async () => {
        const arrayCoordinates = [initialCoordinates, finalCoordinates];
        const sorted = sortBy(arrayCoordinates, (c) => c.x && c.y);

        const payload: CalibratePayload = {
            topX: sorted[0].x,
            topY: sorted[0].y,
            bottomX: sorted[1].x,
            bottomY: sorted[1].y,
        };

        if (lastCalibration) {
            const res = await calibrateDevice(id, lastCalibration.id, payload);
            if (res && !error && !isLoading) {
                setRecalibrate(false);
                setNewCalibration(undefined);
                getDeviceCalibrations(id);
                toast.success('Calibration coordinates successfully sent to device!');
            } else {
                toast.error(error?.message ?? 'Failed to send calibration coordinates to device.');
            }
        }
    }, [calibrateDevice, initialCoordinates, finalCoordinates, id, lastCalibration]);

    const handlePagination = useCallback(
        (params: Partial<PaginatedParams>) => {
            if (paginator) {
                setPaginator({ ...paginator, page: params.page, size: paginator.size });
            } else {
                setPaginator({ ...defaultPagination, page: params.page });
            }
        },
        [paginator]
    );

    const alert = useMemo(
        () =>
            !device?.connected && (
                <Alert type="danger">Your device is disconnected. Connect your device to be able to calibrate.</Alert>
            ),
        [device]
    );

    return (
        <Container fluid className="mt-2">
            <WeighbridgeHeader
                title="Calibrations"
                buttonText="Go back"
                onClick={() => history.push(`/weighbridge/ready/${id}`)}
            />
            {alert}

            {recalibrate ? (
                <>
                    {lastCalibration?.base64Image ? (
                        <>
                            <CalibrateImage
                                image={lastCalibration.base64Image}
                                updateFinalCoordinates={setFinalCoordinates}
                                updateInitialCoordinates={setInitialCoordinates}
                            />
                            <div className="d-flex justify-content-center mt-4">
                                <Button className="me-2" onClick={() => setRecalibrate(false)}>
                                    Cancel
                                </Button>
                                <Button onClick={() => getCalibrationPayload()}>Save calibration</Button>
                            </div>
                        </>
                    ) : (
                        <div className="d-flex flex-column">
                            <div className=" d-flex justify-content-center">
                                <ImageSkeleton />
                            </div>
                            <div className="d-flex justify-content-center mt-4">
                                <Button className="me-2" onClick={() => setRecalibrate(false)}>
                                    Cancel
                                </Button>
                                <Button className="me-2" onClick={async () => await getDevice(id)}>
                                    Update
                                </Button>
                            </div>
                        </div>
                    )}
                </>
            ) : (
                <>
                    <div className="d-flex justify-content-end">
                        <Button onClick={() => getCalibration()}>Recalibrate</Button>
                    </div>
                    <CalibrationsTable
                        data={calibrations}
                        onDetails={(calibrationId) =>
                            history.push(`/weighbridge/ready/${id}/calibrations/${calibrationId}`)
                        }
                        onPagination={handlePagination}
                        loading={isLoading}
                    />
                </>
            )}
        </Container>
    );
};

export default ListCalibrations;
