import { ListGroup as BootstrapListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from 'theme/colors';

export const StyledSideBar = styled.aside<{ collapsed: number }>`
    background: ${colors.darkPurple};
    height: 100vh;
    position: sticky;
    left: 0;
    min-width: ${({ collapsed }) => (collapsed ? '64px' : '265px')};
    max-width: ${({ collapsed }) => (collapsed ? '64px' : '265px')};
`;

export const LogoH1 = styled.span`
    margin-left: 16px;
    color: white;
`;

export const LogoSmall = styled.span`
    justify-content: center;
    margin-left: 16px;
    text-align: center;
`;

export const LogoImg = styled.img`
    width: 132px;
    margin-top: 16px;
`;

export const LogoSmallImg = styled.img`
    width: 24px;
    margin-top: 16px;
`;

export const ListGroup = styled(BootstrapListGroup)`
    padding: 16px;
`;

export const Item = styled.span<{ collapsed: boolean }>`
    padding: ${({ collapsed }) => (collapsed ? '0' : '0 16px')};
    justify-content: ${({ collapsed }) => (collapsed ? 'center' : 'none')};
    height: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
    background: none !important;
    color: ${colors.grey};
    border: none;
    border-radius: 4px;
    &:hover {
        color: white;
    }
    &.active {
        background: white !important;
        color: ${colors.darkPurple};
    }
`;

export const StyledLink = styled(Link)`
    text-decoration: none;
`;
