import { PaginatedParams } from 'core/http/pagination';
import { handleError } from 'state/state-utils';
import { listCustomers } from './client';

export const doListCustomers: Function = (params?: Partial<PaginatedParams>) => async (dispatch: Function) => {
    dispatch({ type: 'LIST_CUSTOMERS_REQUEST' });
    try {
        const payload = await listCustomers(params);
        dispatch({
            type: 'LIST_CUSTOMERS_SUCCESS',
            payload,
        });
        return payload;
    } catch (e) {
        handleError(dispatch, 'LIST_CUSTOMERS_ERROR', e);
    }
};
