import ImageSkeleton from 'components/ui/image-skeleton/ImageSkeleton';
import LabelValue from 'components/ui/label-value/LabelValue';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Calibration } from 'state/weighbridge/weighbridge';
import { defaultCanvas, emptyCoordinate, ImageCoordinates } from './image';

interface Props {
    image?: string;
    updateInitialCoordinates?: (c: ImageCoordinates) => void;
    updateFinalCoordinates?: (c: ImageCoordinates) => void;
    drawEnabled?: boolean;
    calibration?: Calibration;
}

const Recalibrate: FC<Props> = ({
    image,
    updateInitialCoordinates,
    updateFinalCoordinates,
    drawEnabled = true,
    calibration,
}) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [isPainting, setIsPainting] = useState(false);
    const [imageObj, setImageObj] = useState<HTMLImageElement>(new Image());
    const [initialCoordinates, setInitialCoordinates] = useState<ImageCoordinates>(emptyCoordinate);
    const [finalCoordinates, setFinalCoordinates] = useState<ImageCoordinates>(emptyCoordinate);
    useEffect(() => {
        const drawRect = (initialCoordinates: ImageCoordinates, finalCoordinates: ImageCoordinates) => {
            if (!canvasRef.current) {
                return;
            }
            if (initialCoordinates && finalCoordinates) {
                const canvas: HTMLCanvasElement = canvasRef.current;
                const context = canvas.getContext('2d');
                if (context && imageObj) {
                    context.drawImage(imageObj, 0, 0, imageObj.width, imageObj.height);
                    context.strokeStyle = 'red';
                    context.lineWidth = 4;
                    const rectangleWidth = finalCoordinates.x - initialCoordinates.x;
                    const rectangleHeight = finalCoordinates.y - initialCoordinates.y;
                    context.strokeRect(initialCoordinates.x, initialCoordinates.y, rectangleWidth, rectangleHeight);
                }
            }
        };

        if (calibration && imageObj.width > 0 && imageObj.height > 0) {
            const initialCoordinates = { x: calibration?.topLeftX ?? 0, y: calibration?.topLeftY ?? 0 };
            const finalCoordinates = { x: calibration?.bottomRightX ?? 0, y: calibration?.bottomRightY ?? 0 };
            drawRect(initialCoordinates, finalCoordinates);
            setInitialCoordinates(initialCoordinates);
            setFinalCoordinates(finalCoordinates);
        } else {
            if (image) {
                drawImg();
            }
        }
    }, [calibration, imageObj, image]);

    const drawImg = useCallback(() => {
        if (!canvasRef.current) {
            return;
        }
        const context = canvasRef.current.getContext('2d');
        if (context) {
            const img = new Image();
            img.onload = () => context.drawImage(img, 0, 0, img.width, img.height);
            img.src = `data:image/jpeg;base64,${image}`;
            setImageObj(img);
        }
    }, [canvasRef, image]);

    useEffect(() => {
        if (image) {
            drawImg();
        }
    }, [image]);

    const getCoordinates = (event: MouseEvent) => {
        if (!canvasRef.current) {
            return;
        }
        const canvas: HTMLCanvasElement = canvasRef.current;
        return { x: event.pageX - canvas.offsetLeft, y: event.pageY - canvas.offsetTop };
    };

    const startPaint = useCallback(
        (e: MouseEvent) => {
            if (drawEnabled) {
                const coordinates = getCoordinates(e);
                if (image) {
                    drawImg();
                }
                if (coordinates) {
                    setInitialCoordinates({ ...coordinates });
                    updateInitialCoordinates && updateInitialCoordinates({ ...coordinates });
                    setIsPainting(true);
                }
            }
        },
        [drawImg, getCoordinates, updateInitialCoordinates, drawEnabled, image]
    );

    useEffect(() => {
        if (!canvasRef.current) {
            return;
        }
        const canvas: HTMLCanvasElement = canvasRef.current;
        canvas.addEventListener('mousedown', startPaint);
        return () => {
            canvas.removeEventListener('mousedown', startPaint);
        };
    }, [startPaint]);

    const exitPaint = useCallback(() => {
        setIsPainting(false);
    }, [isPainting]);

    useEffect(() => {
        if (!canvasRef.current) {
            return;
        }
        const canvas: HTMLCanvasElement = canvasRef.current;
        canvas.addEventListener('mouseup', exitPaint);
        return () => {
            canvas.removeEventListener('mouseup', exitPaint);
        };
    }, [exitPaint]);

    const paint = useCallback(
        (e: MouseEvent) => {
            if (!canvasRef.current) {
                return;
            }
            if (isPainting && drawEnabled) {
                const coordinates = getCoordinates(e);
                const canvas: HTMLCanvasElement = canvasRef.current;
                const context = canvas.getContext('2d');
                if (context && imageObj && coordinates) {
                    context.clearRect(0, 0, imageObj.width, imageObj.height);
                    context.drawImage(imageObj, 0, 0, imageObj.width, imageObj.height);
                    context.strokeStyle = 'red';
                    context.lineWidth = 4;
                    setFinalCoordinates({ ...coordinates });
                    updateFinalCoordinates && updateFinalCoordinates({ ...coordinates });
                    const rectangleWidth = e.pageX - canvas.offsetLeft - initialCoordinates.x;
                    const rectangleHeight = e.pageY - canvas.offsetTop - initialCoordinates.y;
                    context.strokeRect(initialCoordinates.x, initialCoordinates.y, rectangleWidth, rectangleHeight);
                }
            }
        },
        [isPainting, getCoordinates, updateFinalCoordinates, drawEnabled, imageObj]
    );

    useEffect(() => {
        if (!canvasRef.current) {
            return;
        }
        const canvas: HTMLCanvasElement = canvasRef.current;
        canvas.addEventListener('mousemove', paint);
        return () => {
            canvas.removeEventListener('mousemove', paint);
        };
    }, [paint]);

    const imageCanvas = useMemo(() => {
        if (imageObj?.height > 0 && imageObj?.width > 0) {
            return <canvas ref={canvasRef} height={imageObj?.height} width={imageObj?.width} />;
        } else {
            return <canvas ref={canvasRef} height={defaultCanvas.height} width={defaultCanvas.width} />;
        }
    }, [imageObj?.width, imageObj?.height]);

    return (
        <div className="d-flex flex-column">
            <div className="d-flex justify-content-center">{image ? imageCanvas : <ImageSkeleton />}</div>
            <div className="d-flex justify-content-center">
                {initialCoordinates && finalCoordinates && (
                    <div className="mt-5">
                        <div className="d-flex">
                            <div className="mb-3 me-5">
                                <strong>Top Left Point:</strong>
                                <div className="ms-4">
                                    <LabelValue label="x" value={`${initialCoordinates?.x}`} />
                                    <LabelValue label="y" value={`${initialCoordinates?.y}`} />
                                </div>
                            </div>
                            <div className="mb-3">
                                <strong>Bottom Right Point:</strong>
                                <div className="ms-4">
                                    <LabelValue label="x" value={`${finalCoordinates.x}`} />
                                    <LabelValue label="y" value={`${finalCoordinates.y}`} />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Recalibrate;
