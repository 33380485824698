export interface Weight {
    id: string;
    requestType: string;
    autoWeight: string;
    manualWeight: number;
    driver: string;
    customerId: string;
    locationName: string;
    dispatcher: string;
    traceId: string;
    capturedWeightImage: string;
    createdAt: string;
    createdBy: string;
}

export type UpdateWeight = Pick<Weight, 'manualWeight' | 'customerId' | 'dispatcher' | 'driver'>;

export enum WeighbridgeStatus {
    NEW = 'NEW',
    READY = 'READY',
    PENDING_SETUP = 'PENDING_SETUP',
}

export interface Coordinate {
    id?: string;
    latitude: number;
    longitude: number;
    orderIndex: number;
}

export interface Calibration {
    id: string;
    base64Image: string;
    topLeftX: number;
    topLeftY: number;
    bottomRightX: number;
    bottomRightY: number;
    traceId: string;
    createdAt: string;
    createdBy: string;
}

export interface CalibratePayload {
    topX: number;
    topY: number;
    bottomX: number;
    bottomY: number;
}

export interface Device {
    id: string;
    deviceId: string;
    token: string;
    name: string;
    dispatcher: string;
    status: string;
    customerId?: string;
    locationName: string;
    city: string;
    country: string;
    geofenceCoordinates: Coordinate[];
    calibrations: Calibration[];
    connected: boolean;
}

export type CreateDevicePayload = Pick<
    Device,
    'name' | 'dispatcher' | 'customerId' | 'locationName' | 'city' | 'country'
>;

export const emptyCreateDevicePayload: CreateDevicePayload = {
    name: '',
    customerId: '',
    locationName: '',
    dispatcher: '',
    city: '',
    country: '',
};
