import { FC } from 'react';

export const EmptyTable: FC = () => {
    return (
        <svg width="80" height="80" viewBox="0 0 1024 1024" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M518.3 459C517.552 458.044 516.595 457.271 515.504 456.739C514.412 456.207 513.214 455.931 512 455.931C510.786 455.931 509.588 456.207 508.496 456.739C507.404 457.271 506.448 458.044 505.7 459L393.7 600.7C392.777 601.879 392.204 603.294 392.047 604.784C391.89 606.273 392.155 607.776 392.812 609.122C393.469 610.468 394.492 611.601 395.763 612.393C397.034 613.185 398.502 613.603 400 613.6H473.9V856C473.9 860.4 477.5 864 481.9 864H541.9C546.3 864 549.9 860.4 549.9 856V613.7H624C630.7 613.7 634.4 606 630.3 600.8L518.3 459Z"
                fill="black"
            />
            <path
                d="M811.4 366.7C765.6 245.9 648.9 160 512.2 160C375.5 160 258.8 245.8 213 366.6C127.3 389.1 64 467.2 64 560C64 670.5 153.5 760 263.9 760H304C308.4 760 312 756.4 312 752V692C312 687.6 308.4 684 304 684H263.9C230.2 684 198.5 670.6 174.9 646.3C151.4 622.1 138.9 589.5 140 555.7C140.9 529.3 149.9 504.5 166.2 483.6C182.9 462.3 206.3 446.8 232.3 439.9L270.2 430L284.1 393.4C292.7 370.6 304.7 349.3 319.8 330C334.707 310.871 352.365 294.055 372.2 280.1C413.3 251.2 461.7 235.9 512.2 235.9C562.7 235.9 611.1 251.2 652.2 280.1C672.1 294.1 689.7 310.9 704.6 330C719.7 349.3 731.7 370.7 740.3 393.4L754.1 429.9L791.9 439.9C846.1 454.5 884 503.8 884 560C884 593.1 871.1 624.3 847.7 647.7C836.224 659.243 822.573 668.394 807.536 674.625C792.5 680.856 776.376 684.043 760.1 684H720C715.6 684 712 687.6 712 692V752C712 756.4 715.6 760 720 760H760.1C870.5 760 960 670.5 960 560C960 467.3 896.9 389.3 811.4 366.7Z"
                fill="black"
            />
        </svg>
    );
};

export default EmptyTable;
