export const enumToText = (enumValue: any) => {
    const splited = enumValue.split('_')
    let text = ''
    splited.forEach((value: string) => {
        text += `${value.charAt(0)}${value
            .toLowerCase()
            .substring(1, value.length)} `
    })
    return enumValue ? text : ''
}
