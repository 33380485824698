import { FC } from 'react';
import { Spinner } from 'react-bootstrap';

interface Props {
    isLoading?: boolean;
}
export const Loader: FC<Props> = ({ isLoading, children }) => {
    return isLoading ? (
        <div className="d-flex justify-content-center align-items-center h-100">
            <Spinner variant="primary" animation="border" role="status"></Spinner>
        </div>
    ) : (
        <>{children}</>
    );
};

export default Loader;
