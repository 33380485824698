import Check from 'components/icons/Check';
import Cross from 'components/icons/Cross';
import Trash from 'components/icons/Trash';
import Button from 'components/ui/button/Button';
import Table, { TableColumns } from 'components/ui/table/Table';
import { PaginatedParams } from 'core/http/pagination';
import { ListPayload } from 'core/http/response';
import { enumToText } from 'core/utils/enum-to-text';
import { FC } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Device } from 'state/weighbridge/weighbridge';
import { colors } from 'theme/colors';

interface Props {
    data?: ListPayload<Device>;
    loading?: boolean;
    onDetails: (device: Device) => void;
    onDelete?: (id: string) => void;
    onPagination?: (paginator: Partial<PaginatedParams>) => void;
}

const WeighBridgeTable: FC<Props> = ({ data, onDetails, onDelete, onPagination, loading }) => {
    const columns: TableColumns[] = [
        {
            title: 'Id',
            render: (record: Device) => (
                <OverlayTrigger overlay={<Tooltip>{record.id}</Tooltip>}>
                    <span>{`${record.id.substring(0, 7)}...`}</span>
                </OverlayTrigger>
            ),
        },
        {
            title: 'Name',
            render: (record: Device) => record.name,
        },
        {
            title: 'Status',
            render: (record: Device) => (
                <div className="d-flex align-items-center">
                    {!record.connected ? <Cross color={colors.error} /> : <Check color={colors.success} />}
                    {enumToText(record.status)}
                </div>
            ),
        },

        {
            title: 'Customer Id',
            render: (record: Device) =>
                record.customerId ? (
                    <OverlayTrigger overlay={<Tooltip>{record.customerId}</Tooltip>}>
                        {record?.customerId ? (
                            <span>{`${record?.customerId?.substring(0, 7)}...`}</span>
                        ) : (
                            <span></span>
                        )}
                    </OverlayTrigger>
                ) : (
                    record.customerId
                ),
        },
        {
            title: 'Dispatcher',
            render: (record: Device) => record.dispatcher,
        },
        {
            title: 'Location',
            render: (record: Device) => record.locationName,
        },
        {
            title: 'Country',
            render: (record: Device) => record.country,
        },
        {
            title: '',
            render: (record: Device) => {
                return (
                    <Button size="sm" onClick={() => onDelete && onDelete(record?.id)}>
                        <Trash /> delete
                    </Button>
                );
            },
        },
    ];

    return (
        <>
            <Table
                loading={loading}
                columns={columns}
                data={data}
                hover
                onRow={(i) => {
                    if (onDetails && data?.list) {
                        onDetails(data?.list[i]);
                    }
                }}
                onPagination={onPagination}
            />
        </>
    );
};
export default WeighBridgeTable;
