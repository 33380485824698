import Collapse from 'components/icons/Collapse';
import useSession from 'hooks/useSession';
import { FC } from 'react';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { StyledNavbar, StyledNavbarBrand } from './Header.styles';

interface Props {
    collapsed: boolean;
    setCollapsed: Function;
}
const Header: FC<Props> = ({ collapsed, setCollapsed }) => {
    const { logout, userInfo } = useSession();

    return (
        <>
            <StyledNavbar className="shadow-sm" fixed="top" collapsed={+collapsed}>
                <StyledNavbarBrand onClick={() => setCollapsed(!collapsed)}>
                    <Collapse />
                </StyledNavbarBrand>

                <Navbar.Collapse className="justify-content-end">
                    <Nav>
                        <NavDropdown align="end" title={userInfo?.email} id="basic-nav-dropdown">
                            <NavDropdown.Item
                                className="w-100"
                                onClick={() => {
                                    logout();
                                }}
                            >
                                Logout
                            </NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </StyledNavbar>
        </>
    );
};
export default Header;
