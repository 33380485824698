import { SelectOption } from 'components/ui/form/form-select/FormSelect';

export interface Customer {
    id: string;
    name: string;
    address: Address;
    website: string;
    email?: string;
    phone?: string;
    hasCertificates?: boolean;
    type: string;
    brokerIds: string[];
    brokerOfIds: string[];
    eori: string;
    vat: string;
    referenceCode?: string;
    deferredAccountNumber?: string;
    specialInstructions?: string;
    traderAccountNumber?: string;
    vatNumber?: string;
    contact?: CustomerContact;
}

export interface CustomerContact {
    name?: string;
    email?: string;
    phoneNumber?: string;
    phoneCountryCode?: string;
}

export interface Address {
    addressLine1: string;
    addressLine2: string;
    postCode: string;
    city: string;
    country: string;
}

export enum CustomerType {
    TRADER = 'TRADER',
    CUSTOMS_BROKER = 'CUSTOMS_BROKER',
    HAULIER = 'HAULIER',
}

export const normalizeCustomersToSelect = (values?: Customer[]): SelectOption[] =>
    values?.length
        ? values.map((item) => ({
              id: item.id,
              value: item.name,
          }))
        : [];
