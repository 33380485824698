import MapboxDraw from '@mapbox/mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { config } from 'config/config';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Coordinate } from 'state/weighbridge/weighbridge';
import { colors } from 'theme/colors';
import { sortCoordinates } from 'views/weightbridge/utils/weightbridge-utils';
import Loader from '../loader/Loader';
import { defaultLatitude, defaultLongitude, MapActions, Polygon } from './map';

mapboxgl.accessToken = config.mapAccessToken ?? '';

interface Props {
    drawEnabled?: boolean;
    onDraw?: (p: Polygon) => void;
    onDelete?: () => void;
    width?: number | string;
    height?: number | string;
    coordinates?: Coordinate[];
}
const MapContainer: FC<Props> = ({ drawEnabled, onDraw, onDelete, width, height, coordinates }) => {
    const mapDiv = useRef<HTMLDivElement>(null);
    const [map, setMap] = useState<null | mapboxgl.Map>(null);

    const firstCoordinates = useMemo(() => {
        if (coordinates) {
            const sorted = sortCoordinates(coordinates);
            return [
                sorted[0]?.latitude ?? defaultLatitude,
                sorted[0]?.longitude ?? defaultLongitude,
            ] as mapboxgl.LngLatLike;
        } else {
            return [defaultLatitude, defaultLongitude] as mapboxgl.LngLatLike;
        }
    }, [coordinates]);

    const addMarker = useCallback((createdMap: mapboxgl.Map, position: mapboxgl.LngLatLike) => {
        const marker = new mapboxgl.Marker();
        marker.setLngLat(position);
        marker.addTo(createdMap);
    }, []);

    const initializeMap = useCallback(() => {
        if (!mapDiv.current) {
            return;
        }

        const createdMap = new mapboxgl.Map({
            container: mapDiv.current ?? '',
            style: 'mapbox://styles/mapbox/outdoors-v11',
            center: firstCoordinates,
            zoom: 10,
        });

        if (drawEnabled) {
            const draw = new MapboxDraw({
                displayControlsDefault: false,
                controls: {
                    point: true,
                    trash: true,
                    polygon: true,
                },
                defaultMode: 'draw_polygon',
            });
            createdMap.addControl(draw);
        } else {
            createdMap.addControl(new mapboxgl.NavigationControl());
        }

        setMap(createdMap);
    }, [map, mapDiv, drawEnabled, coordinates]);

    useEffect(() => {
        initializeMap();
    }, []);

    useEffect(() => {
        if (drawEnabled) {
            const onDrawCreate = ({ features }: any) => {
                const polygon = features[0].geometry;
                onDraw && onDraw(polygon);
            };

            const onDrawUpdate = ({ features }: any) => {
                const polygon = features[0].geometry;
                onDraw && onDraw(polygon);
            };

            const onDrawDelete = () => {
                onDelete && onDelete();
            };

            if (map) {
                map.on(MapActions.CREATE, (e: unknown) => onDrawCreate(e));
                map.on(MapActions.DELETE, () => onDrawDelete());
                map.on(MapActions.UPDATE, (e: unknown) => onDrawUpdate(e));
            }
        }
    }, [map, drawEnabled]);

    useEffect(() => {
        const guid = () => {
            const s4 = () => {
                return Math.floor((1 + Math.random()) * 0x10000)
                    .toString(16)
                    .substring(1);
            };
            return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
        };

        if (map && coordinates) {
            map.on('load', () => {
                if (coordinates.length === 1) {
                    const coordsArray = [coordinates[0]?.latitude, coordinates[0]?.longitude] as mapboxgl.LngLatLike;
                    addMarker(map, coordsArray);
                } else {
                    if (coordinates.length > 1) {
                        const sorted = sortCoordinates(coordinates);
                        const coordsArray = sorted.map((c) => [c?.latitude, c?.longitude]);
                        const polygonId = `polygon-id-${sorted[0]?.id}-${guid()}`;
                        map.addSource(polygonId, {
                            type: 'geojson',
                            data: {
                                type: 'Feature',
                                geometry: {
                                    type: 'Polygon',
                                    coordinates: [coordsArray],
                                },
                                properties: {},
                            },
                        });
                        const polygonLayerId = `polygon-layer-id-${sorted[0]?.id}-${guid()}`;
                        map.addLayer({
                            id: polygonLayerId,
                            type: 'fill',
                            source: polygonId, // reference the data source
                            paint: {
                                'fill-color': colors.blue,
                                'fill-opacity': 0.2,
                            },
                        });
                        const polygonOutlineId = `polygon-outline-id-${sorted[0]?.id}-${guid()}`;
                        map.addLayer({
                            id: polygonOutlineId,
                            type: 'line',
                            source: polygonId,
                            paint: {
                                'line-color': colors.blue,
                                'line-width': 2,
                            },
                        });
                    }
                }
            });
        }
    }, [map, coordinates]);

    const mapboxStyleIsLoading = useMemo(() => {
        if (map && map.isStyleLoaded()) {
            return true;
        } else {
            return false;
        }
    }, [map]);

    return (
        <Loader isLoading={mapboxStyleIsLoading}>
            <div className="Map" ref={mapDiv} style={{ height: height ?? '50vh', width: width ?? '100%' }} />
        </Loader>
    );
};
export default MapContainer;
