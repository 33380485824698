import LabelValue from 'components/ui/label-value/LabelValue';
import { FC } from 'react';
import { Device } from 'state/weighbridge/weighbridge';

interface Props {
    device: Device;
    title?: React.ReactNode;
}

export const DeviceInfo: FC<Props> = ({ device, title }) => {
    return (
        <>
            {!title ? <h4 className="mb-4">Device information</h4> : title}
            <LabelValue label="Device id" value={device?.id} />
            <LabelValue label="Customer id" value={device?.customerId} />
            <LabelValue label="Token" value={device?.token} />
            <LabelValue label="Dispatcher" value={device?.dispatcher} />
            <LabelValue label="Location Name" value={device?.locationName} />
            <LabelValue label="City" value={device?.city} />
            <LabelValue label="Country" value={device?.country} />
        </>
    );
};

export default DeviceInfo;
