export interface ImageCoordinates {
    x: number;
    y: number;
}

export const emptyCoordinate: ImageCoordinates = {
    x: 0,
    y: 0,
};

export const defaultCanvas = { height: 166, width: 565 };
