import Button from 'components/ui/button/Button';
import { FC } from 'react';
import { Col, Row } from 'react-bootstrap';

interface Props {
    title: string;
    buttonText: string;
    onClick: () => void;
}
const WeighbridgeHeader: FC<Props> = ({ title, buttonText, onClick }) => {
    return (
        <Row className="mb-5">
            <Col>
                <h3>{title}</h3>
            </Col>
            <Col>
                <div className="d-flex justify-content-end">
                    <Button
                        onClick={() => {
                            onClick();
                        }}
                    >
                        {buttonText}
                    </Button>
                </div>
            </Col>
        </Row>
    );
};

export default WeighbridgeHeader;
