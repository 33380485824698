import { Alert } from 'components/ui/alert/Alert';
import Loader from 'components/ui/loader/Loader';
import Map from 'components/ui/map/MapContainer';
import { defaultPagination, PaginatedParams } from 'core/http/pagination';
import useWeighbridge from 'hooks/useWeighbridge';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import WeighbridgeHeader from '../WeighbridgeHeader';
import WeighingTable from '../weighing/WeighingTable';
import DeviceInfo from './DeviceInfo';

const WeighbridgeReady: FC = () => {
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    const { device, weights, getDevice, getDeviceWeights, getNewWeight, error, isLoading } = useWeighbridge();
    const [paginator, setPaginator] = useState<Partial<PaginatedParams>>(defaultPagination);

    useEffect(() => {
        getDevice(id);

        if (!paginator) {
            getDeviceWeights(id, defaultPagination);
        }
    }, []);

    useEffect(() => {
        if (paginator) {
            getDeviceWeights(id, paginator);
        }
    }, [paginator]);

    const handlePagination = useCallback(
        (params: Partial<PaginatedParams>) => {
            if (paginator) {
                setPaginator({ ...paginator, page: params.page, size: paginator.size });
            } else {
                setPaginator({ ...defaultPagination, page: params.page });
            }
        },
        [paginator]
    );

    const requestWeight = useCallback(async () => {
        const w = await getNewWeight(id);
        if (!!w && !error && !isLoading) {
            await getDeviceWeights(id);
            toast.success('Request weight with success!');
        } else {
            toast.error(error?.message ?? 'Failed to request weight.');
        }
    }, [getDeviceWeights, getNewWeight, id]);

    const alert = useMemo(
        () =>
            !device?.connected && (
                <Alert type="danger">
                    Your device is disconnected. Connect your device to be able to calibrate and request weight.
                </Alert>
            ),
        [device]
    );

    return (
        <Loader isLoading={isLoading}>
            <Container fluid className="mt-2">
                <WeighbridgeHeader
                    title="Weighbridge device"
                    onClick={() => {
                        history.push({
                            pathname: `/weighbridge`,
                        });
                    }}
                    buttonText="Go back"
                />
                {alert}

                <Row className="mb-5">
                    <Col sm={9}>{device?.geofenceCoordinates && <Map coordinates={device?.geofenceCoordinates} />}</Col>
                    <Col sm={3}>
                        {device && <DeviceInfo device={device} />}
                        <Button onClick={() => history.push(`/weighbridge/ready/${id}/calibrations`)} className="mt-4">
                            Calibrations
                        </Button>
                    </Col>
                </Row>
                <Row className="mt-5 mb-3">
                    <Col>
                        <h4>Weighing</h4>
                    </Col>
                    <Col className="d-flex justify-content-end">
                        <Button onClick={() => requestWeight()}>Request Weight</Button>
                    </Col>
                </Row>
                <WeighingTable
                    data={weights}
                    onDetails={(weightId) => history.push(`/weighbridge/ready/${id}/weighing/${weightId}`)}
                    onPagination={handlePagination}
                    loading={isLoading}
                />
            </Container>
        </Loader>
    );
};

export default WeighbridgeReady;
