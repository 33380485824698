import { FC, useCallback, useMemo } from 'react';
import { Pagination as BPagination } from 'react-bootstrap';
import { Paginator } from './paginator';

interface Props {
    paginator: Paginator;
    onChanged?: (paginator: Paginator) => void;
}
export const Pagination: FC<Props> = ({ paginator, onChanged }) => {
    const totalPages = useMemo(() => Math.ceil(paginator.totalElements / paginator.elementsPerPage), [paginator]);
    const displayedCurrentPage = useMemo(() => paginator.currentPage + 1, [paginator]);

    const previousPage = useCallback(() => {
        const currentPage = Math.max(0, paginator.currentPage - 1);
        const p: Paginator = { ...paginator, currentPage: currentPage };
        onChanged && onChanged(p);
    }, [paginator, onChanged]);

    const nextPage = useCallback(() => {
        const currentPage = Math.min(totalPages - 1, displayedCurrentPage);
        const p: Paginator = { ...paginator, currentPage: currentPage };
        onChanged && onChanged(p);
    }, [paginator, onChanged, totalPages, displayedCurrentPage]);

    return (
        <div className="d-flex justify-content-center">
            <BPagination>
                {paginator.currentPage - 1 >= 0 ? (
                    <>
                        <BPagination.Prev onClick={() => previousPage()} />
                        <BPagination.Item onClick={() => previousPage()}>{displayedCurrentPage - 1}</BPagination.Item>
                    </>
                ) : (
                    <BPagination.Prev disabled />
                )}

                <BPagination.Item active>{displayedCurrentPage}</BPagination.Item>

                {displayedCurrentPage + 1 <= totalPages ? (
                    <>
                        <BPagination.Item onClick={() => nextPage()}>{displayedCurrentPage + 1}</BPagination.Item>
                        <BPagination.Next onClick={() => nextPage()} />
                    </>
                ) : (
                    <BPagination.Next disabled />
                )}
            </BPagination>
        </div>
    );
};

export default Pagination;
