import { FC } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import styled from 'styled-components';

interface Props {
    label: string;
    value?: string | number;
}

export const Value = styled.p`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: 0;
`;

export const Label = styled.strong`
    white-space: nowrap;
    margin-bottom: 0;
`;

export const LabelValue: FC<Props> = ({ label, value }) => {
    return (
        <div className="d-flex">
            <Label className="me-2"> {label}:</Label>
            {!!value ? (
                <OverlayTrigger overlay={<Tooltip>{value}</Tooltip>}>
                    <Value>{value ?? 'N/A'}</Value>
                </OverlayTrigger>
            ) : (
                <p className="mb-0">N/A</p>
            )}
        </div>
    );
};

export default LabelValue;
