import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { doSignIn, doUpdateToken } from 'state/session/actions';

const useSession = () => {
    const dispatch = useAppDispatch();

    const getToken = () => {
        return localStorage.getItem('auth') ? localStorage.getItem('auth') : sessionStorage.getItem('auth');
    };

    const session = useAppSelector((state) => {
        const initState = { ...state.session };
        const token = getToken();
        if (token) {
            initState.auth = JSON.parse(token);
        }
        return initState;
    });
    const error = useAppSelector((state) => state?.session?.error);

    const isLoading = useAppSelector((state) => state?.session?.isLoading);

    const customerId = useAppSelector((state) => state.session?.auth?.user?.customerId);

    const userInfo = useAppSelector((state) => state.session?.auth?.user);

    const registerUser = useAppSelector((state) => state.session?.userInfo);

    const isLogged = !!session?.auth?.token;

    const signIn = (credentials: { email: string; password: string; remember: boolean }) => {
        return dispatch(doSignIn(credentials));
    };

    const updateToken = () => {
        if (session.auth && session.auth.refreshToken) {
            return dispatch(doUpdateToken(session.auth?.refreshToken));
        } else {
            const token = getToken();
            if (token) {
                return dispatch(doUpdateToken(JSON.parse(token).refreshToken));
            }
        }
    };

    const logout = () => {
        localStorage.removeItem('CREDENTIALS_FLUSH');
        localStorage.removeItem('auth');
        sessionStorage.removeItem('auth');
        localStorage.setItem('CREDENTIALS_FLUSH', new Date().toString());
        dispatch({ type: 'USER_LOGOUT_SUCCESS', payload: null });
    };

    useEffect(() => {
        const token = getToken();
        if (token) {
            dispatch({ type: 'USER_SIGN_IN_SUCCESS', payload: JSON.parse(token) });
        }
    }, [dispatch]);

    return {
        userInfo,
        registerUser,
        isLogged,
        error,
        isLoading,
        customerId,
        signIn,
        updateToken,
        logout,
        getToken,
    };
};

export default useSession;
