import axios from 'axios'
import isEmpty from 'lodash/isEmpty'
import { config } from './config'

const api = axios.create({
    baseURL: config.baseUrl,
})

export const buildQueryStringFromObject = (
    obj: any,
    useQuestionMark = true
) => {
    if (isEmpty(obj)) {
        return ''
    }

    const urlParams = Object.entries(obj).reduce((res, [key, value]) => {
        if (value) {
            if (Array.isArray(value)) {
                return value.reduce(
                    (acc, val) =>
                        `${acc}&${encodeURIComponent(key)}=${encodeURIComponent(
                            val
                        )}`,
                    res
                )
            }

            return `${res}&${encodeURIComponent(key)}=${encodeURIComponent(
                value as string
            )}`
        }

        return res
    }, '')

    const prefix = useQuestionMark ? '?' : ''

    return urlParams.length ? `${prefix}${urlParams}` : ''
}

export default api
