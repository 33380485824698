import Button from 'components/ui/button/Button';
import ImageSkeleton from 'components/ui/image-skeleton/ImageSkeleton';
import useWeighbridge from 'hooks/useWeighbridge';
import { sortBy } from 'lodash';
import { FC, useCallback, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CalibratePayload, Calibration } from 'state/weighbridge/weighbridge';
import CalibrateImage from '../image/CalibrateImage';
import { emptyCoordinate, ImageCoordinates } from '../image/image';
import WeighbridgeHeader from '../WeighbridgeHeader';

export const Calibrate: FC = () => {
    const { id, calibrationId } = useParams<{ id: string; calibrationId: string }>();
    const history = useHistory();
    const [lastCalibration, setLastCalibration] = useState<Calibration>();
    const [initialCoordinates, setInitialCoordinates] = useState<ImageCoordinates>(emptyCoordinate);
    const [finalCoordinates, setFinalCoordinates] = useState<ImageCoordinates>(emptyCoordinate);

    const { device, getDevice, calibrateDevice, error, isLoading } = useWeighbridge();

    useEffect(() => {
        getDevice(id);
    }, []);

    useEffect(() => {
        if (!!device?.calibrations?.length && calibrationId) {
            const calibrations = [...device?.calibrations];
            const last = calibrations.find((c) => c.id === calibrationId);
            if (last) {
                setLastCalibration(last);
            }
        }
    }, [device, calibrationId]);

    const getCalibrationPayload = useCallback(async () => {
        const arrayCoordinates = [initialCoordinates, finalCoordinates];
        const sorted = sortBy(arrayCoordinates, (c) => c.x && c.y);

        const payload: CalibratePayload = {
            topX: sorted[0].x,
            topY: sorted[0].y,
            bottomX: sorted[1].x,
            bottomY: sorted[1].y,
        };

        if (lastCalibration) {
            const res = await calibrateDevice(id, lastCalibration.id, payload);
            if (res && !error && !isLoading) {
                history.goBack();
                toast.success('Device calibrated with success!');
            } else {
                toast.error(error?.message ?? 'Failed to calibrate this device');
            }
        }
    }, [calibrateDevice, initialCoordinates, finalCoordinates, id, lastCalibration]);

    return (
        <Container fluid className="mt-2">
            <WeighbridgeHeader title="Calibration" buttonText="Go back" onClick={() => history.goBack()} />

            {lastCalibration ? (
                <CalibrateImage
                    image={lastCalibration.base64Image}
                    updateFinalCoordinates={setFinalCoordinates}
                    updateInitialCoordinates={setInitialCoordinates}
                />
            ) : (
                <div className="d-flex justify-content-center">
                    <ImageSkeleton />
                </div>
            )}

            <div className="d-flex">
                <Button
                    className="mt-5 me-2"
                    onClick={async () => {
                        await getDevice(id);
                    }}
                >
                    Update
                </Button>
                <Button className="mt-5" onClick={() => getCalibrationPayload()}>
                    Save calibration
                </Button>
            </div>
        </Container>
    );
};

export default Calibrate;
