import { PaginatedParams } from 'core/http/pagination';
import { doListCustomers } from 'state/customers/action';
import { useAppDispatch, useAppSelector } from 'state/hooks';

const useCustomers = () => {
    const customers = useAppSelector((state) => state.customers.customers);
    const isLoading = useAppSelector((state) => state.customers.isLoading);
    const error = useAppSelector((state) => state.customers.error);

    const dispatch = useAppDispatch();

    function listCustomers(params?: Partial<PaginatedParams>) {
        return dispatch(doListCustomers(params));
    }

    return {
        isLoading,
        error,
        customers,
        listCustomers,
    };
};

export default useCustomers;
