import { FC, ReactNode } from 'react';
import { Button, Modal } from 'react-bootstrap';

interface Props {
    title: string | ReactNode;
    show: boolean;
    confirmText?: string | ReactNode;
    cancelText?: string | ReactNode;
    onClose?: () => void;
    onSave?: () => void;
}
export const CustomModal: FC<Props> = ({ title, show, onClose, onSave, children, confirmText, cancelText }) => {
    return (
        <>
            <Modal show={show} centered onHide={onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{children}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => onClose && onClose()}>
                        {cancelText ?? 'Cancel'}
                    </Button>
                    <Button onClick={() => onSave && onSave()}>{confirmText ?? 'Confirm'}</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default CustomModal;
