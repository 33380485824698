import useSession from 'hooks/useSession';
import { FC, useMemo, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import routes from '../../config/routes/routes';
import Header from './components/Header';
import Sidebar from './components/Sidebar';

const Section = styled.section`
    display: flex;
    flex-direction: row;
    height: 100vh;
`;

const Content = styled.div`
    padding-top: 56px;
    overflow: auto;
`;

const Skeleton: FC = () => {
    const { isLogged } = useSession();
    const [collapsed, setCollapsed] = useState<boolean>(false);

    const redirectLogin = useMemo(() => {
        if (!isLogged) {
            return <Redirect to="/" />;
        }
        return <></>;
    }, [isLogged]);

    return (
        <>
            {redirectLogin}
            <Section>
                <Sidebar collapsed={collapsed} />
                <Header collapsed={collapsed} setCollapsed={setCollapsed} />
                <Content className="container-fluid mt-2 mb-2">
                    <Switch>
                        {routes.map((route, index) => {
                            return (
                                <Route
                                    key={index}
                                    path={route.path}
                                    exact={route.exact}
                                    render={(props) => <route.component name={route.name} {...props} />}
                                />
                            );
                        })}
                    </Switch>
                </Content>
            </Section>
        </>
    );
};
export default Skeleton;
