import Button from 'components/ui/button/Button';
import FormInput from 'components/ui/form/form-input/FormInput';
import FormSelectCustomers from 'components/ui/form/form-select/FormSelectCustomers';
import { objectIsEmpty } from 'core/utils/objects';
import { setNestedObjectValues, useFormik } from 'formik';
import useWeighbridge from 'hooks/useWeighbridge';
import { FC, useCallback } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CreateDevicePayload, emptyCreateDevicePayload } from 'state/weighbridge/weighbridge';
import * as Yup from 'yup';
import WeighbridgeHeader from './WeighbridgeHeader';

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required.'),
    customerId: Yup.string().required('Customer is Required.'),
    locationName: Yup.string().required('Location name is Required.'),
    city: Yup.string().required('City name is Required.'),
    country: Yup.string().required('Country name is Required.'),
    dispatcher: Yup.string().required('Dispatcher name is Required.'),
});

const AddDevice: FC = () => {
    const history = useHistory();
    const { addDevice, error, isLoading } = useWeighbridge();

    const formik = useFormik<CreateDevicePayload>({
        initialValues: emptyCreateDevicePayload,
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: () => {},
    });

    const validate = async () => {
        const v = await formik.validateForm().then((v) => {
            formik.setTouched(setNestedObjectValues(v, true));
            return v;
        });
        if (!objectIsEmpty(v)) {
            return false;
        } else {
            return true;
        }
    };

    const generateConfig = useCallback(async () => {
        const valid = await validate();
        if (valid) {
            const values = { ...formik.values };
            const d = await addDevice(values);
            if (!error && !isLoading && d) {
                history.push({
                    pathname: `/weighbridge/pending-setup/${d?.id}`,
                });
                toast.success('Device added with success!');
            } else {
                toast.error(error?.message ?? 'Failed to add device.');
            }
        } else {
            toast.error(error?.message ?? 'Required fields need to be filled.');
        }
    }, [formik.values, error, isLoading]);

    return (
        <Container fluid className="mt-2">
            <WeighbridgeHeader
                title="Add Weighbridge Device"
                buttonText="Go back"
                onClick={() => {
                    history.push({
                        pathname: `/weighbridge`,
                    });
                }}
            />

            <Row className="mt-4">
                <Col sm={6}>
                    <FormInput
                        label="Name"
                        fieldProps={formik.getFieldProps('name')}
                        fieldMeta={formik.getFieldMeta('name')}
                    />
                </Col>
            </Row>

            <Row className="mt-4">
                <Col sm={6}>
                    <FormSelectCustomers
                        fieldProps={formik.getFieldProps('customerId')}
                        fieldMeta={formik.getFieldMeta('customerId')}
                        fieldHelper={formik.getFieldHelpers('customerId')}
                    />
                </Col>
            </Row>
            <Row className="mt-4">
                <Col sm={6}>
                    <FormInput
                        label="Location Name"
                        fieldProps={formik.getFieldProps('locationName')}
                        fieldMeta={formik.getFieldMeta('locationName')}
                    />
                </Col>
            </Row>
            <Row className="mt-4">
                <Col sm={6}>
                    <FormInput
                        label="City"
                        fieldProps={formik.getFieldProps('city')}
                        fieldMeta={formik.getFieldMeta('city')}
                    />
                </Col>
            </Row>
            <Row className="mt-4">
                <Col sm={6}>
                    <FormInput
                        label="Country"
                        fieldProps={formik.getFieldProps('country')}
                        fieldMeta={formik.getFieldMeta('country')}
                    />
                </Col>
            </Row>
            <Row className="mt-4">
                <Col sm={6}>
                    <FormInput
                        label="Dispatcher"
                        fieldProps={formik.getFieldProps('dispatcher')}
                        fieldMeta={formik.getFieldMeta('dispatcher')}
                    />
                </Col>
            </Row>
            <Row className="mt-4">
                <Col sm={6}>
                    <Button onClick={() => generateConfig()}>Add device</Button>
                </Col>
            </Row>
        </Container>
    );
};

export default AddDevice;
