import axiosClient from '../../config/axios'
import { SuccessResponse } from '../../core/http/response'
import { SessionPayload } from './session'

export const signIn = (credentials: {
    email: string
    password: string
    remember: boolean
}): Promise<SessionPayload> =>
    axiosClient
        .post<SuccessResponse<SessionPayload>>(`/auth/session`, credentials)
        .then((response) => response.data.payload)

export const updateToken = (token: string): Promise<SessionPayload> =>
    axiosClient
        .put<SuccessResponse<SessionPayload>>(`/auth/session`, {
            refreshToken: token,
        })
        .then((response) => response.data.payload)
