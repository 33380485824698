import { ErrorResponse, ListPayload } from 'core/http/response';
import { Reducer } from 'redux';
import { Actions } from './actionType';
import { Calibration, Device, Weight } from './weighbridge';

export interface WeighbridgeState {
    isLoading: boolean;
    error?: ErrorResponse;
    devices?: ListPayload<Device>;
    device?: Device;
    weights?: ListPayload<Weight>;
    weight?: Weight;
    calibrations?: ListPayload<Calibration>;
}

export const weighbridgesInitialState: Readonly<WeighbridgeState> = {
    isLoading: false,
};

const weighbridge: Reducer<WeighbridgeState, Actions> = (
    state = weighbridgesInitialState,
    action
): WeighbridgeState => {
    switch (action.type) {
        case 'GET_DEVICES_REQUEST':
            return {
                ...state,
                isLoading: true,
            };
        case 'GET_DEVICES_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                devices: action.payload,
            };
        case 'GET_DEVICES_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case 'GET_DEVICE_REQUEST':
            return {
                ...state,
                isLoading: true,
            };
        case 'GET_DEVICE_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                device: action.payload,
            };
        case 'GET_DEVICE_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case 'ADD_DEVICE_REQUEST':
            return {
                ...state,
                isLoading: true,
            };
        case 'ADD_DEVICE_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                device: action.payload,
            };
        case 'ADD_DEVICE_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case 'UPDATE_DEVICE_REQUEST':
            return {
                ...state,
                isLoading: true,
            };
        case 'UPDATE_DEVICE_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                device: action.payload,
            };
        case 'UPDATE_DEVICE_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case 'DELETE_DEVICE_REQUEST':
            return {
                ...state,
                isLoading: true,
            };
        case 'DELETE_DEVICE_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
            };
        case 'DELETE_DEVICE_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case 'START_CALIBRATION_REQUEST':
            return {
                ...state,
                isLoading: true,
            };
        case 'START_CALIBRATION_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
            };
        case 'START_CALIBRATION_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case 'CALIBRATE_DEVICE_REQUEST':
            return {
                ...state,
                isLoading: true,
            };
        case 'CALIBRATE_DEVICE_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
            };
        case 'CALIBRATE_DEVICE_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case 'GET_WEIGHTS_REQUEST':
            return {
                ...state,
                isLoading: true,
            };
        case 'GET_WEIGHTS_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                weights: action.payload,
            };
        case 'GET_WEIGHTS_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case 'GET_NEW_WEIGHT_REQUEST':
            return {
                ...state,
                isLoading: true,
            };
        case 'GET_NEW_WEIGHT_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                weight: action.payload,
            };
        case 'GET_NEW_WEIGHT_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case 'UPDATE_WEIGHT_REQUEST':
            return {
                ...state,
                isLoading: true,
            };
        case 'UPDATE_WEIGHT_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                weight: action.payload,
            };
        case 'UPDATE_WEIGHT_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case 'GET_DEVICE_CALIBRATIONS_REQUEST':
            return {
                ...state,
                isLoading: true,
            };
        case 'GET_DEVICE_CALIBRATIONS_SUCCESS':
            return {
                ...state,
                isLoading: false,
                error: undefined,
                calibrations: action.payload,
            };
        case 'GET_DEVICE_CALIBRATIONS_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };

        default:
            return state;
    }
};

export default weighbridge;
