import { PaginatedParams } from 'core/http/pagination';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import {
    doAddDevice,
    doCalibrateDevice,
    doDeleteDevice,
    doGetDevice,
    doGetDeviceCalibrations,
    doGetDevices,
    doGetDeviceWeights,
    doGetNewWeight,
    doStartCalibrate,
    doUpdateDevice,
    doUpdateWeight,
} from 'state/weighbridge/action';
import { CalibratePayload, CreateDevicePayload, Device, UpdateWeight } from 'state/weighbridge/weighbridge';

const useWeighbridge = () => {
    const error = useAppSelector((state) => state.weighbridge.error);
    const isLoading = useAppSelector((state) => state.weighbridge.isLoading);
    const devices = useAppSelector((state) => state.weighbridge.devices);
    const device = useAppSelector((state) => state.weighbridge.device);
    const weights = useAppSelector((state) => state.weighbridge.weights);
    const calibrations = useAppSelector((state) => state.weighbridge.calibrations);

    const dispatch = useAppDispatch();

    const getDevices = (params?: Partial<PaginatedParams>) => dispatch(doGetDevices(params));

    const getDevice = (id: string) => dispatch(doGetDevice(id));

    const addDevice = (device: CreateDevicePayload) => dispatch(doAddDevice(device));

    const updateDevice = (device: Partial<Device>) => dispatch(doUpdateDevice(device));

    const deleteDevice = (id: string) => dispatch(doDeleteDevice(id));

    const startCalibrate = (id: string) => dispatch(doStartCalibrate(id));

    const calibrateDevice = (id: string, calibrationId: string, calibration: CalibratePayload) =>
        dispatch(doCalibrateDevice(id, calibrationId, calibration));

    const getDeviceWeights = (id: string, params?: Partial<PaginatedParams>) =>
        dispatch(doGetDeviceWeights(id, params));

    const getNewWeight = (id: string) => dispatch(doGetNewWeight(id));

    const updateWeight = (id: string, toUpdate: UpdateWeight) => dispatch(doUpdateWeight(id, toUpdate));

    const getDeviceCalibrations = (id: string, params?: Partial<PaginatedParams>) =>
        dispatch(doGetDeviceCalibrations(id, params));

    return {
        isLoading,
        error,
        devices,
        device,
        weights,
        calibrations,
        getDevices,
        getDevice,
        addDevice,
        updateDevice,
        deleteDevice,
        startCalibrate,
        calibrateDevice,
        getDeviceWeights,
        getNewWeight,
        updateWeight,
        getDeviceCalibrations,
    };
};

export default useWeighbridge;
