import { FC, ReactNode, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Item } from './Sidebar.styles';

interface SidebarEntryProps {
    linkTo: string;
    title: string;
    icon: ReactNode;
    collapsed: boolean;
}
const SidebarEntry: FC<SidebarEntryProps> = ({ linkTo, title, icon, collapsed }) => {
    const location = useLocation();
    const history = useHistory();

    const [active, setActive] = useState<boolean>(false);
    useEffect(() => {
        if (location.pathname.includes(linkTo)) {
            setActive(true);
        } else {
            setActive(false);
        }
    }, [location.pathname, linkTo]);

    return (
        <Item
            collapsed={collapsed}
            className={active ? 'active' : ''}
            onClick={() => {
                history.push(linkTo);
                setActive(true);
            }}
        >
            {collapsed ? (
                <>{icon}</>
            ) : (
                <>
                    {icon}
                    <span style={{ marginLeft: '8px' }}>{title}</span>
                </>
            )}
        </Item>
    );
};

export default SidebarEntry;
