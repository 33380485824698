import { PaginatedParams } from 'core/http/pagination';
import { handleError } from 'state/state-utils';
import {
    addDevice,
    calibrateDevice,
    deleteDevice,
    getDevice,
    getDeviceCalibrations,
    getDevices,
    getDeviceWeights,
    getWeight,
    startCalibration,
    updateDevice,
    updateWeight,
} from './client';
import { CalibratePayload, CreateDevicePayload, Device, UpdateWeight } from './weighbridge';

export const doGetDevices: Function = (params?: Partial<PaginatedParams>) => async (dispatch: Function) => {
    dispatch({ type: 'GET_DEVICES_REQUEST' });
    try {
        const payload = await getDevices(params);
        dispatch({
            type: 'GET_DEVICES_SUCCESS',
            payload,
        });
        return payload;
    } catch (e: any) {
        handleError(dispatch, 'GET_DEVICES_ERROR', e);
    }
};

export const doGetDevice: Function = (id: string) => async (dispatch: Function) => {
    dispatch({ type: 'GET_DEVICE_REQUEST' });
    try {
        const payload = await getDevice(id);
        dispatch({
            type: 'GET_DEVICE_SUCCESS',
            payload,
        });
        return payload;
    } catch (e) {
        handleError(dispatch, 'GET_DEVICE_ERROR', e);
    }
};

export const doAddDevice: Function = (device: CreateDevicePayload) => async (dispatch: Function) => {
    dispatch({ type: 'ADD_DEVICE_REQUEST' });

    try {
        const payload = await addDevice(device);
        dispatch({
            type: 'ADD_DEVICE_SUCCESS',
            payload,
        });
        return payload;
    } catch (e) {
        handleError(dispatch, 'ADD_DEVICE_ERROR', e);
    }
};

export const doUpdateDevice: Function = (device: Partial<Device>) => async (dispatch: Function) => {
    dispatch({ type: 'UPDATE_DEVICE_REQUEST' });

    try {
        const payload = await updateDevice(device);
        dispatch({
            type: 'UPDATE_DEVICE_SUCCESS',
            payload,
        });
        return payload;
    } catch (e: any) {
        handleError(dispatch, 'UPDATE_DEVICE_ERROR', e);
    }
};
export const doDeleteDevice: Function = (deviceId: string) => async (dispatch: Function) => {
    dispatch({ type: 'DELETE_DEVICE_REQUEST' });

    try {
        const payload = await deleteDevice(deviceId);
        dispatch({
            type: 'DELETE_DEVICE_SUCCESS',
            payload,
        });
        return payload;
    } catch (e: any) {
        handleError(dispatch, 'DELETE_DEVICE_ERROR', e);
    }
};

export const doStartCalibrate: Function = (deviceId: string) => async (dispatch: Function) => {
    dispatch({ type: 'START_CALIBRATION_REQUEST' });

    try {
        const payload = await startCalibration(deviceId);
        dispatch({
            type: 'START_CALIBRATION_SUCCESS',
            payload,
        });
        return payload;
    } catch (e: any) {
        handleError(dispatch, 'START_CALIBRATION_ERROR', e);
    }
};

export const doCalibrateDevice: Function =
    (deviceId: string, calibrationId: string, calibratePayload: CalibratePayload) => async (dispatch: Function) => {
        dispatch({ type: 'START_CALIBRATION_REQUEST' });

        try {
            const payload = await calibrateDevice(deviceId, calibrationId, calibratePayload);
            dispatch({
                type: 'START_CALIBRATION_SUCCESS',
                payload,
            });
            return payload;
        } catch (e) {
            handleError(dispatch, 'START_CALIBRATION_ERROR', e);
        }
    };

export const doGetDeviceWeights: Function =
    (deviceId: string, params: Partial<PaginatedParams>) => async (dispatch: Function) => {
        dispatch({ type: 'GET_WEIGHTS_REQUEST' });

        try {
            const payload = await getDeviceWeights(deviceId, params);
            dispatch({
                type: 'GET_WEIGHTS_SUCCESS',
                payload,
            });
            return payload;
        } catch (e) {
            handleError(dispatch, 'GET_WEIGHTS_ERROR', e);
        }
    };

export const doGetDeviceCalibrations: Function =
    (deviceId: string, params: Partial<PaginatedParams>) => async (dispatch: Function) => {
        dispatch({ type: 'GET_DEVICE_CALIBRATIONS_REQUEST' });

        try {
            const payload = await getDeviceCalibrations(deviceId, params);
            dispatch({
                type: 'GET_DEVICE_CALIBRATIONS_SUCCESS',
                payload,
            });
            return payload;
        } catch (e) {
            handleError(dispatch, 'GET_DEVICE_CALIBRATIONS_ERROR', e);
        }
    };

export const doGetNewWeight: Function = (deviceId: string) => async (dispatch: Function) => {
    dispatch({ type: 'GET_NEW_WEIGHT_REQUEST' });

    try {
        const payload = await getWeight(deviceId);
        dispatch({
            type: 'GET_NEW_WEIGHT_SUCCESS',
            payload,
        });
        return payload;
    } catch (e) {
        handleError(dispatch, 'GET_NEW_WEIGHT_ERROR', e);
    }
};

export const doUpdateWeight: Function = (weightId: string, toUpdate: UpdateWeight) => async (dispatch: Function) => {
    dispatch({ type: 'UPDATE_WEIGHT_REQUEST' });

    try {
        const payload = await updateWeight(weightId, toUpdate);
        dispatch({
            type: 'UPDATE_WEIGHT_SUCCESS',
            payload,
        });
        return payload;
    } catch (e) {
        handleError(dispatch, 'UPDATE_WEIGHT_ERROR', e);
    }
};
