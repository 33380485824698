import Home from 'components/icons/Home';
import Truck from 'components/icons/Truck';
import { FC } from 'react';
import logoSmall from './../../../assets/logo-small.svg';
import logo from './../../../assets/logo.svg';
import { ListGroup, LogoH1, LogoImg, LogoSmall, LogoSmallImg, StyledSideBar } from './Sidebar.styles';
import SidebarEntry from './SidebarEntry';

interface Props {
    collapsed: boolean;
}
const Sidebar: FC<Props> = ({ collapsed }) => {
    return (
        <>
            <StyledSideBar collapsed={+collapsed}>
                <div>
                    {collapsed ? (
                        <LogoSmall>
                            <LogoSmallImg src={logoSmall} alt="ddispatch" />
                        </LogoSmall>
                    ) : (
                        <LogoH1>
                            <LogoImg src={logo} alt="ddispatch" />
                        </LogoH1>
                    )}
                </div>
                <ListGroup>
                    <SidebarEntry linkTo="/home" title="Home" icon={<Home />} collapsed={collapsed} />
                    <SidebarEntry linkTo="/weighbridge" title="Weighbridge" icon={<Truck />} collapsed={collapsed} />
                </ListGroup>
            </StyledSideBar>
        </>
    );
};
export default Sidebar;
